import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Select, InputNumber, Spin, Alert, Radio, Result, Switch } from 'antd';
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { useForm, FormProvider } from "react-hook-form";
import states from "../../shared/states.json";
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import FormItemDouble from '../../components/FormItemDouble/FormItemDouble';
import FormButtons from "../../components/FormButtons/FormButtons";
import Fieldset from "../../components/FormFieldset/FormFieldset";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { isObjectNotEmpty, removeEmpty } from "../../shared/objectUtils";
import logger from "../../shared/logger";

const { Title, Paragraph } = Typography;

const baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;

const Carriers = (props) => {
	//#region useForms

	const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

	//#endregion
	//#region useStates

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	//#endregion
	//#region onSubmit

	const onSubmit = (data) => {
		data.entityTypes = ["CARRIER"];
		// console.log(data);

		let cleanedData = removeEmpty(data);
		// console.log(cleanedData);

		if (isObjectNotEmpty(cleanedData)) {
			setError(null);
			setIsLoading(true);
			axios({
				method: 'post',
				url: `${baseURL}/accountRegistrations`,
				data: cleanedData,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			}).then(res => {
				// console.log(res);
				// console.log(res.data);
				setIsLoading(false);
				setIsFormSubmitted(true);
				window.scrollTo(0, 0);
			}).catch(error => {
				logger.logErrorEvent('Carriers.js', error, error.message, true);
				setIsLoading(false);
				setError(error.message);
				setIsFormSubmitted(false);
			});
		}
	};

	//#endregion
	//#region styles

	const formItemLayout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};

	const formItemLayoutHorizontal = {
		labelCol: { span: 20 },
		wrapperCol: { span: 4 },
	};

	const formItemLayoutDouble = {
		labelCol1: { span: 16 },
		wrapperCol1: { span: 16 },
		labelCol2: { span: 8 },
		wrapperCol2: { span: 8 },
	};

	const formItemLayoutDouble2 = {
		labelCol1: { span: 24 },
		wrapperCol1: { span: 12 },
		labelCol2: { span: 0 },
		wrapperCol2: { span: 12 },
	};

	const formItemLayoutDoubleHorizontal = {
		labelCol1: { span: 12 },
		wrapperCol1: { span: 1 },
		labelCol2: { span: 6 },
		wrapperCol2: { span: 5 },
	};

	//#endregion

	if (isFormSubmitted === true) {
		return (
			<div id="Confirmation">
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<SectionHeader size={2} title="CARRIER APPLICATION" />
					</Col>
				</Row>
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<Result
							status="success"
							title="Thank you for submitting your application!"
							subTitle="We will be in touch with you soon!"
						/>
					</Col>
				</Row>
			</div>
		);
	} else {
		return (
			<Spin spinning={isLoading === true && error === null}>
				<div>
					<Row>
						<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
							<SectionHeader size={2} title="CARRIER APPLICATION" />
						</Col>
					</Row>
					<Row>
						<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
							<Card bordered={false}>
								<Paragraph>Thank you for your interest in joining the Iris Freight Carrier Network. Here’s what you’ll need to get started:</Paragraph>
								<ul>
									<li>Authority: Active DOT / MC Number</li>
									<li>Safety Rating: Satisfactory or None</li>
									<li>Insurance Requirements:
										<ul>
											<li>Auto Liability $1,000,000</li>
											<li>Cargo Liability $100,000</li>
											<li>Commercial General Liability $1,000,000</li>
											<li>Workers’ Comp. / Employer’s Liability – Statutory / $1,000,000</li>
										</ul>
									</li>
								</ul>
								<Paragraph>Please fill out the form below and a member of our team will be in touch with you regarding next steps!</Paragraph>

								<Title level={4}>Step 1: Fill out the form below.</Title>
								<FormProvider {...methods}>
									<Form className="login-form" onSubmit={methods.handleSubmit(onSubmit)}>
										<Fieldset legend="Company Contact Information (* indicates a required field)">
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Company Name" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Company Name"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="name"
													/>
													<FormItemDouble {...formItemLayoutDouble2} label1="Contact Name" required1 required2 format="vertical"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="First Name"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules1={{ required: 'Required Field' }}
														name1="pointOfContact.firstName"
														render2={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Last Name"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules2={{ required: 'Required Field' }}
														name2="pointOfContact.lastName"
													/>
													<FormItem {...formItemLayout} label="Email Address" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Email Address"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{
															required: 'Required Field',
															minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
															pattern: {
																value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
																message: "Please enter a valid email address"
															}
														}}
														name="email"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Federal Id/EIN" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="EIN"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="ein"
													/>
													<FormItemDouble {...formItemLayoutDouble} label1="Day Time Phone #" required1 format="vertical"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Phone #"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules1={{
															required: 'Required Field',
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="phone"
														render2={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Ext"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules2={{ required: false }}
														name2="phoneExt"
													/>
													<FormItemDouble {...formItemLayoutDouble} label1="After Hours Phone #" required1 format="vertical"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Phone #"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules1={{
															required: 'Required Field',
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="afterHoursPhone"
														render2={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Ext"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules2={{ required: false }}
														name2="afterHoursPhoneExt"
													/>
													<FormItem {...formItemLayout} label="Fax #" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Fax #"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="fax"
													/>
												</Col>
											</Row>
										</Fieldset>
										<Fieldset legend="Company Address (* indicates a required field)">
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 1" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Street Address 1"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.streetAddress1"
													/>
													<FormItem {...formItemLayout} label="City" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="City"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.city"
													/>
													<FormItem {...formItemLayout} label="Postal Code" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Postal Code"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.postalCode"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 2" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Street Address 2"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="companyAddress.streetAddress2"
													/>
													<FormItem {...formItemLayout} label="State" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Select
																placeholder="Please Select a State"
																allowClear={true}
																style={{ width: '100%' }}
																virtual={false}
																showSearch={true}
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																options={states.map(s => { return { label: s.name, value: s.abbreviation } })}
																onBlur={onBlur}
																onChange={(selected) => { onChange(selected); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.state"
													/>
													<FormItem {...formItemLayout} label="Country" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Select
																placeholder="Please Select a Country"
																allowClear={true}
																style={{ width: '100%' }}
																virtual={false}
																showSearch={true}
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																options={[{ label: "United States", value: "USA" }]}
																onBlur={onBlur}
																onChange={(selected) => { onChange(selected); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.country"
													/>
												</Col>
											</Row>
										</Fieldset>
										<Fieldset legend="Carrier Information (* indicates a required field)">
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="US Docket # (MC, FF, MX)" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="MC Number"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="mcNum"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="US DOT #" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="DOT #"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="dotNum"
													/>
												</Col>
											</Row>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of Power Units" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="assetNeeds.numPowerUnits"
													/>
												</Col>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of Trailers" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="assetNeeds.numTrailers"
													/>
												</Col>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of Drivers" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="assetNeeds.numDrivers"
													/>
												</Col>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of HazMat Drivers" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="assetNeeds.numHazMatDrivers"
													/>
												</Col>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of Teams" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="assetNeeds.numTeams"
													/>
												</Col>
											</Row>
										</Fieldset>
										<Fieldset legend="Accounts Receivable (* indicates a required field)">
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Preferred Payment Method" format="vertical" required
														render={({ onChange, onBlur, value, name, ref }) => (
															<Radio.Group
																onBlur={onBlur}
																onChange={e => onChange(e.target.value)}
																value={value}
																name={name}
																buttonStyle="solid"
																ref={ref}
															>
																<Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
																<Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
																<Radio.Button value="FACTORING">Factoring</Radio.Button>
															</Radio.Group>
														)}
														rules={{ required: 'Required Field' }}
														name="preferredPaymentMethod"
													/>
												</Col>
											</Row>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col span={24}>
													<Paragraph>If your accounts receivable department is different than your physical address, please complete below:</Paragraph>
												</Col>
											</Row>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItemDouble {...formItemLayoutDouble2} label1="A/R Contact" format="vertical"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="First Name"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules1={{ required: false }}
														name1="accountsReceivable.firstName"
														render2={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Last Name"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules2={{ required: false }}
														name2="accountsReceivable.lastName"
													/>
													<FormItem {...formItemLayout} label="Email" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Email"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{
															required: false,
															pattern: {
																value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
																message: "Please enter a valid email address"
															}
														}}
														name="accountsReceivable.email"
														defaultValue={''}
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="vertical"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Phone #"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules1={{
															required: false,
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="accountsReceivable.phone"
														defaultValue1={''}
														render2={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Ext"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules2={{ required: false }}
														name2="accountsReceivable.phoneExt"
													/>
												</Col>
											</Row>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 1" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Street Address 1"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="accountsReceivableAddress.streetAddress1"
													/>
													<FormItem {...formItemLayout} label="City" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="City"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="accountsReceivableAddress.city"
													/>
													<FormItem {...formItemLayout} label="Postal Code" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Postal Code"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="accountsReceivableAddress.postalCode"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 2" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Street Address 2"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="accountsReceivableAddress.streetAddress2"
													/>
													<FormItem {...formItemLayout} label="State" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Select
																placeholder="Please Select a State"
																allowClear={true}
																style={{ width: '100%' }}
																virtual={false}
																showSearch={true}
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																options={states.map(s => { return { label: s.name, value: s.abbreviation } })}
																onBlur={onBlur}
																onChange={(selected) => { onChange(selected); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="accountsReceivableAddress.state"
													/>
													<FormItem {...formItemLayout} label="Country" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Select
																placeholder="Please Select a Country"
																allowClear={true}
																style={{ width: '100%' }}
																virtual={false}
																showSearch={true}
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																options={[{ label: "United States", value: "USA" }]}
																onBlur={onBlur}
																onChange={(selected) => { onChange(selected); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="accountsReceivableAddress.country"
													/>
												</Col>
											</Row>
										</Fieldset>
										<Fieldset legend="Trailer Types">
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<h4 style={{ textAlign: 'center' }}>Reefers</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name1="assetNeeds.has48Reefer"
														defaultValue1={false}
														render2={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														name2="assetNeeds.num48Reefer"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name1="assetNeeds.has53Reefer"
														defaultValue1={false}
														render2={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														name2="assetNeeds.num53Reefer"
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you haul frozen?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.hasReeferFrozen"
														defaultValue={false}
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name1="assetNeeds.has48DryVan"
														defaultValue1={false}
														render2={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														name2="assetNeeds.num48DryVan"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name1="assetNeeds.has53DryVan"
														defaultValue1={false}
														render2={({ onChange, onBlur, value, name, ref }) => (
															<InputNumber
																min={0}
																precision={0}
																step={1}
																onBlur={onBlur}
																onChange={e => { onChange(e); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														name2="assetNeeds.num53DryVan"
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the dry vans?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.hasDryVanLiftGates"
														defaultValue={false}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you have roller beds for the dry vans?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.hasDryVanRollerBed"
														defaultValue={false}
													/>
													<h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
													<FormItem {...formItemLayoutHorizontal} label="Do you haul over dimensional/overweight loads?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.hasOverSized"
														defaultValue={false}
													/>
												</Col>
											</Row>
											{/* <h4>Flatbeds</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.has48Flatbed"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.num48Flatbed"
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.has53Flatbed"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.num53Flatbed"
											/>
											<h4>Low Boys</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Low Boys?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasLowBoy"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numLowBoy"
											/>
											<h4>RGNs</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have RGNs?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasRGN"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numRGN"
											/>
											<h4>Step Decks</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks with Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasStepDeckRamps"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numStepDeckRamps"
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks without Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasStepDeckNoRamps"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numStepDeckNoRamps"
											/>
											<h4>Tankers</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Single Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasTankersSingleCompartment"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numTankersSingleCompartment"
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Multi Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasTankersMultiCompartment"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numTankersMultiCompartment"
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Fiber Glass Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasTankersFiberGlass"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numTankersFiberGlass"
											/>
											<h4>Straight Trucks</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Cubed Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasCubedStraightTruck"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numCubedStraightTruck"
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 26' Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.has26StraightTruck"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.num26StraightTruck"
											/>
											<FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the straight trucks?" noColon format="horizontal"
												render={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name="assetNeeds.hasStraightTruckLiftGates"
												defaultValue={false}
											/>
											<h4>Sprinter Vans</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Sprinter Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasSprinterVan"
												defaultValue1={false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numSprinterVan"
											/> */}
										</Fieldset>
										{error && <Alert message={error} type="error" />}
										<FormButtons
											containerStyle={{
												right: 0,
												width: '100%',
												borderTop: '1px solid #e9e9e9',
												padding: '10px 16px',
												background: '#fff',
												textAlign: 'right',
											}}
											submitDisabled={isLoading === true && error === null}
											submitText="Submit"
										/>
									</Form>
								</FormProvider>
							</Card>
						</Col>
					</Row>
				</div>
			</Spin>
		);
	}
}

export default withRouter(Carriers);
