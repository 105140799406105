import React from 'react';
import { isBooleanFalse, isStringNotEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = { fontWeight: 'bold' }, noColon = false, title = null, value, units = null, timeZone = null, dataType = 'String', extra = null }) => {
    return (
        <div className={className} style={style}>
            {isStringNotEmpty(title) ? (<span className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </span>) : null}
            {isStringNotEmpty(value) ? (<span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString(dataType, value, units, timeZone)}{extra}</span>) : null}
        </div>
    );
};

export default DataRow;