import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import './assets/css/iris.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: "https://3880c244fee543b2b379463bfb0c2bb7@o392346.ingest.sentry.io/5247823",
    environment: process.env.REACT_APP_ENVIRONMENT
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
