import React, { useState } from "react";
import { Button, Input, Alert } from 'antd';
import axios from 'axios';
import ReCaptcha from 'react-recaptcha';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useForm, FormProvider } from 'react-hook-form';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import FormItemDisplay from "../FormItemDisplay/FormItemDisplay";
import logger from "../../shared/logger";

const { TextArea } = Input;

const baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;

const ContactForm = (props) => {
	//#region useForms

	const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

	//#endregion
	//#region useStates
	const [captchaIsValid, setcaptchaIsValid] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	//#endregion
	//#region captcha methods

	const verifyCaptchaCallback = (response) => {
		// console.log(response);

		setcaptchaIsValid(true);
	};

	//#endregion
	//#region onSubmit

	const onSubmit = (data) => {
		// console.log(data);

		if (captchaIsValid === false) {
			setIsFormSubmitted(false);
			return;
		}

		const name = data.name;
		const emailAddress = data.emailAddress;
		const phoneNumber = data.phoneNumber;
		const message = data.message;

		const emailBody = name + " sent you a message.<br><br>Email Address: " + emailAddress + "<br>Phone Number: " + phoneNumber + "<br>Message: " + message;
		const emailSubject = name + " sent you a message on IRISFreight.com";
		// console.log(emailBody);
		// console.log(emailSubject);

		const emailPayload = {
			body: emailBody,
			subject: emailSubject
		};

		axios({
			method: 'post',
			url: `${baseURL}/emailLanding`,
			data: emailPayload,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		}).then((res) => {
			// console.log(res);
			// console.log(res.data);

			setIsFormSubmitted(true);
		}).catch((error) => {
			logger.logErrorEvent('ContactForm.js', error, error.message, true);
		});
	};

	//#endregion
	//#region styles

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	const tailFormItemLayout = {
		wrapperCol: {
			xs: {
				span: 24,
				offset: 0,
			},
			sm: {
				span: 16,
				offset: 8,
			},
		},
	};

	//#endregion

	if (isFormSubmitted === false) {
		return (
			<div id="ContactForm">
				<FormProvider {...methods}>
					<Form onSubmit={methods.handleSubmit(onSubmit)} className="login-form">
						<FormItem {...formItemLayout} label="Name" noColon required format="horizontal"
							render={({ onChange, onBlur, value, name, ref }) => (
								<Input
									placeholder="Name"
									prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
									onBlur={onBlur}
									onChange={e => { onChange(e.target.value); }}
									value={value}
									name={name}
									ref={ref}
								/>
							)}
							rules={{ required: 'Required Field' }}
							name="name"
						/>
						<FormItem {...formItemLayout} label="Email Address" noColon required format="horizontal"
							render={({ onChange, onBlur, value, name, ref }) => (
								<Input
									placeholder="Email Address"
									prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
									onBlur={onBlur}
									onChange={e => { onChange(e.target.value); }}
									value={value}
									name={name}
									ref={ref}
								/>
							)}
							rules={{
								required: 'Required Field',
								minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
								pattern: {
									value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
									message: "Please enter a valid email address"
								}
							}}
							name="emailAddress"
						/>
						<FormItem {...formItemLayout} label="Phone Number" noColon required format="horizontal"
							render={({ onChange, onBlur, value, name, ref }) => (
								<Input
									placeholder="Phone Number"
									prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
									onBlur={onBlur}
									onChange={e => { onChange(e.target.value); }}
									value={value}
									name={name}
									ref={ref}
								/>
							)}
							rules={{
								required: 'Required Field',
								pattern: {
									value: /^\d{10}$/,
									message: "Please enter a valid 10 digit phone number with no special characters"
								}
							}}
							name="phoneNumber"
						/>
						<FormItem {...formItemLayout} label="Message" noColon required format="horizontal"
							render={({ onChange, onBlur, value, name, ref }) => (
								<TextArea
									rows={4}
									autoSize={{ minRows: 4 }}
									placeholder="Message"
									onBlur={onBlur}
									onChange={e => { onChange(e.target.value); }}
									value={value}
									name={name}
									ref={ref}
								/>
							)}
							rules={{ required: 'Required Field' }}
							name="message"
						/>
						<FormItemDisplay {...tailFormItemLayout} format="horizontal">
							<ReCaptcha
								sitekey="6LfOpcAUAAAAAHOCDbOOzhu5Qgd4L8a4DLfYBOKE"
								verifyCallback={verifyCaptchaCallback}
							/>
						</FormItemDisplay>
						<FormItemDisplay {...tailFormItemLayout} format="horizontal">
							<Button type="primary" htmlType="submit" style={{ width: '100%' }} className="contact-form-button" disabled={captchaIsValid === false}>
								Send
          				</Button>
						</FormItemDisplay>
					</Form>
				</FormProvider>
			</div>
		);
	} else {
		return (
			<div id="EmailConfirmation">
				<Alert message="Thank you for your message!" type="success" showIcon />
			</div>
		);
	}
};

export default ContactForm;