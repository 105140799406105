import * as Sentry from '@sentry/browser';
import { isNotNullOrUndefined } from './objectUtils';

const environmentName = process.env.REACT_APP_ENVIRONMENT;
const logReduxErrorEvent = (error, errorMessage, writeToConsole = false) => {
    try {
        if (isNotNullOrUndefined(error)) {
            Sentry.captureException(error);
        }
    } catch (ex) {
        // console.log(ex);
    }

    if (writeToConsole === true) {
        try {
            console.log(errorMessage);
            console.log(error);
        } catch (ex) {
            // console.log(ex);
        }
    }
};

const logErrorEvent = (eventName, error, errorMessage, writeToConsole = false) => {
    try {
        if (isNotNullOrUndefined(error)) {
            Sentry.captureException(error);
        }
    } catch (ex) {
        // console.log(ex);
    }

    if (writeToConsole === true) {
        try {
            console.log(errorMessage);
            console.log(error);
        } catch (ex) {
            // console.log(ex);
        }
    }
};

const logEvent = (eventName, message, writeToConsole = false) => {
    try {
        Sentry.captureMessage(`${eventName}: ${message}`, Sentry.Severity.Log);
    } catch (ex) {
        // console.log(ex);
    }

    if (writeToConsole === true) {
        try {
            console.log(message);
        } catch (ex) {
            // console.log(ex);
        }
    }
};

const logDebugEvent = (eventName, message, writeToConsole = false) => {
    if (environmentName.toUpperCase() === 'DEVELOPMENT') {
        try {
            Sentry.captureMessage(`${eventName}: ${message}`, Sentry.Severity.Debug);
        } catch (ex) {
            // console.log(ex);
        }

        if (writeToConsole === true) {
            try {
                console.log(message);
            } catch (ex) {
                // console.log(ex);
            }
        }
    }
};
export default {
    logReduxErrorEvent,
    logErrorEvent,
    logEvent,
    logDebugEvent
};