import React from "react";
import { Typography, Card } from 'antd';

const { Title } = Typography;

const CardTitleBody = ({ size, title, children }) => {
    return (
        <Card bordered={false}>
            <Typography>
                <Title level={size}>
                    {title}
                </Title>
                {children}
            </Typography>
        </Card>
    )
}

export default CardTitleBody;