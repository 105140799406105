import React from "react";
import { Typography, Card } from 'antd';

const { Title, Text } = Typography;

const CardTitleSubtitle = ({ title, subtitle, image, size = 3 }) => {
    return (
        <Card style={{ paddingTop: 50, backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(' + image + ')', backgroundSize: 'cover', backgroundPosition: 'center center', height: 260, maxWidth: '100%', minWidth: '100%', overflow: 'hidden', overflowWrap: 'break-word' }} bordered={false}>
            <Typography>
                <Title level={size} style={{ color: '#ffffff' }}>{title}</Title>
                <Text strong={true} style={{ color: '#ffffff' }}>{subtitle}</Text>
            </Typography>
        </Card>
    )
}

export default CardTitleSubtitle;