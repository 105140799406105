import React from "react";
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const TitleSubtitle = ({ size = 3, title, subtitle }) => {
    return (
        <Typography>
            <Title level={size} className="center-text section-header">{title}</Title>
            <Paragraph className="center-text section-subheader">{subtitle}</Paragraph>
        </Typography>
    )
}

export default TitleSubtitle;