// created by Garrett Lang on 1/29/2020

// usage: 
// <pre>{JSON.stringify({
//   'Enum Keys' : Enums.AppointmentTypes.keys(),
//   'Enum Vals' : Enums.AppointmentTypes.values(),
//   'Example' : {
//     'HAVE_APPOINTMENT' : Enums.AppointmentTypes.getValueByName('HAVE_APPOINTMENT'),
//     'Val(Need Appointment)' : Enums.AppointmentTypes.getNameByValue('Need Appointment')
//   }
// }, null, 4)}</pre>

import React from 'react';
import { Select } from "antd";

const { Option } = Select;

// enum prototype
function Enum() {
    this.self = arguments[0];
}

Enum.prototype.keys = function () {
    return Object.keys(this.self);
};

Enum.prototype.values = function () {
    let me = this;
    return this.keys(this.self).map(function (key) {
        return me.self[key];
    });
};

Enum.prototype.getValueByName = function (key) {
    if (key !== undefined && key !== null) {
        return this.self[this.keys(this.self).filter(function (k) {
            return key === k;
        }).pop() || ''];
    } else {
        return '';
    }
};

Enum.prototype.getNameByValue = function (value) {
    if (value !== undefined && value !== null) {
        let me = this;
        return this.keys(this.self).filter(function (k) {
            return me.self[k] === value;
        }).pop() || '';
    } else {
        return '';
    }
};

Enum.prototype.selectListOptions = function () {
    let me = this;
    let enumList = this.keys(this.self).map(function (key) {
        return { key, value: me.self[key] };
    });

    let options = [];
    if (enumList.length >= 0) {
        enumList.forEach(function (item) {
            options.push(<Option value={item.key} key={item.key}>{item.value}</Option>);
        });
    }

    return options;
};

Enum.prototype.selectListOptionsWithExclusions = function (exclusionKeyList) {
    let me = this;
    let enumList = this.keys(this.self).map(function (key) {
        return { key, value: me.self[key] };
    });

    let options = [];
    if (enumList.length >= 0) {
        enumList.forEach(function (item) {
            if (!exclusionKeyList.includes(item.key)) {
                options.push(<Option value={item.key} key={item.key}>{item.value}</Option>);
            }
        });
    }

    return options;
};

// array of value/text of name/value
Enum.prototype.getTextValueEnumArray = function () {
    let me = this;
    let enumList = this.keys(this.self).map(function (key) {
        return { value: key, text: me.self[key] };
    });

    return enumList;
};

// array of value/text of just the names
Enum.prototype.getTextValueNameArray = function () {
    let enumList = this.keys(this.self).map(function (key) {
        return { value: key, text: key };
    });

    return enumList;
};

// array of value/text of just the values
Enum.prototype.getTextValueValueArray = function () {
    let me = this;
    let enumList = this.keys(this.self).map(function (key) {
        return { value: me.self[key], text: me.self[key] };
    });

    return enumList;
};

// define all enums below

const AppointmentTypes = new Enum({
    FIRST_COME_FIRST_SERVE: 'First Come First Serve',
    HAVE_APPOINTMENT: 'Have Appointment',
    NEED_APPOINTMENT: 'Need Appointment',
});

const StopTypes = new Enum({
    ASSET_BRKDOWN: 'Asset Breakdown',
    ORIGIN: 'Origin',
    WORK: 'Work',
    WAYPOINT: 'Waypoint',
    FUEL_STOP: 'Fuel Stop',
    REST_STOP_SHORT: 'Rest Stop - Short',
    REST_STOP_LONG: 'Rest Stop - Long',
    REST_STOP_CYCLE_RESET: 'Rest Stop - Cycle Reset',
    DESTINATION: 'Destination',
    REST_STOP_FULL_DAY: 'Rest Stop - Full Day',
    REST_STOP_DRIVER_SWITCH: 'Rest Stop - Driver Switch',
    PICK_UP: 'Pick-Up',
    DROP_OFF: 'Drop-Off',
    TRANSLOAD: 'Transload',
});

const UnitPackaging = new Enum({
    CASES: 'Cases',
    BUSHELS: 'Bushels',
    BAGS: 'Bags',
    LUGS: 'Lugs',
    FLATS: 'Flats',
    GALS: 'Gallons',
    TONS: 'Tons',
});

const BulkPackaging = new Enum({
    PALLETS: 'Pallets',
    ITEMS: 'Items',
});

const LineItemNames = new Enum({
    DETENTION: 'Detention',
    LAYOVER: 'Layover',
    TONU: 'TONU',
    DRIVER_ASSIST_ACCESSORIAL: 'Driver Assist Accessorial',
    LUMPER_FEE_ACCESSORIAL: 'Lumper Fee Accessorial',
    REDIRECT_FEE: 'Redirect Fee',
    OUT_OF_ROUTE_MILES: 'Out of Route Miles Fee',
    DRIVER_ASSIST: 'Driver Assist',
    LUMPER_FEE: 'Lumper Fee',
    TWIC_REQUIRED: 'TWIC Required',
    TEAM_DRIVING: 'Team Driving',
    CARRIER_WASHOUT: 'Carrier Washout',
    LIFT_GATE: 'Lift Gate',
    PALLET_JACK: 'Pallet Jack',
    FOOD_GRADE_TRAILER: 'Food Grade Trailer',
    LOAD_LOCKS: 'Load Locks',
    BULKHEADS: 'Bulkheads',
    PIPES_STACKS: 'Pipe Stacks',
    TARPING: 'Tarping',
    PROTECTIVE_GEAR: 'Protective Gear',
    SHRINK_WRAP: 'Shrink Wrap',
    FUEL_SURCHARGE: 'Fuel Surcharge Fee Per Mile',
    BASE_FEE_PER_MILE: 'Base Fee Per Mile',
    SHORT_HAUL_SURCHARGE: 'Short Haul Surcharge Fee',
    LOCATION_FEE: 'IRIS Location Fee',
    BROKERAGE_FEE: 'IRIS Brokerage Fee',
    TEMPERATURE_FEE: 'IRIS Temperature Fee',
    DIGITIZATION_FEE: 'IRIS Digitization Fee',
    CONTRACT_RATE: 'Contract Rate',
    BROKERAGE_FEE_ADJUSTMENT: 'IRIS Brokerage Fee Adjustment',
    OPEN_ADJUSTMENT: 'Open Adjustment',
    FEE_ADJUSTMENT: 'Fee Adjustment',
    CARRIER_BROKERAGE_FEE_ADJUSTMENT: 'IRIS Brokerage Fee Adjustment for Carrier',
});

const Accessorials = new Enum({
    DETENTION: 'Detention',
    LAYOVER: 'Layover',
    TONU: 'TONU',
    DRIVER_ASSIST_ACCESSORIAL: 'Driver Assist Accessorial',
    LUMPER_FEE_ACCESSORIAL: 'Lumper Fee Accessorial',
    REDIRECT_FEE: 'Redirect Fee',
    OUT_OF_ROUTE_MILES: 'Out of Route Miles Fee',
});

const StopFees = new Enum({
    DRIVER_ASSIST: 'Driver Assist',
    LUMPER_FEE: 'Lumper Fee',
});

const LoadRequirements = new Enum({
    TWIC_REQUIRED: 'TWIC Required',
    TEAM_DRIVING: 'Team Driving',
});

const EquipmentSpecifics = new Enum({
    CARRIER_WASHOUT: 'Carrier Washout',
    LIFT_GATE: 'Lift Gate',
    PALLET_JACK: 'Pallet Jack',
    FOOD_GRADE_TRAILER: 'Food Grade Trailer',
    LOAD_LOCKS: 'Load Locks',
    BULKHEADS: 'Bulkheads',
    PIPES_STACKS: 'Pipe Stacks',
    TARPING: 'Tarping',
    PROTECTIVE_GEAR: 'Protective Gear',
    SHRINK_WRAP: 'Shrink Wrap',
});

const LoadFees = new Enum({
    FUEL_SURCHARGE: 'Fuel Surcharge Fee Per Mile',
    BASE_FEE_PER_MILE: 'Base Fee Per Mile',
    SHORT_HAUL_SURCHARGE: 'Short Haul Surcharge Fee',
});

const IRISFees = new Enum({
    LOCATION_FEE: 'IRIS Location Fee',
    BROKERAGE_FEE: 'IRIS Brokerage Fee',
    TEMPERATURE_FEE: 'IRIS Temperature Fee',
    DIGITIZATION_FEE: 'IRIS Digitization Fee',
    CONTRACT_RATE: 'Contract Rate',
});

const Adjustments = new Enum({
    BROKERAGE_FEE_ADJUSTMENT: 'IRIS Brokerage Fee Adjustment',
    OPEN_ADJUSTMENT: 'Open Adjustment',
    FEE_ADJUSTMENT: 'Fee Adjustment',
    CARRIER_BROKERAGE_FEE_ADJUSTMENT: 'IRIS Brokerage Fee Adjustment for Carrier',
});

const LineItemTypes = new Enum({
    ACCESSORIAL: 'Accessorial',
    LOAD_REQUIREMENT: 'Load Requirement',
    EQUIPMENT_SPECIFIC: 'Equipment Specific',
    IRIS_FEE: 'IRIS Fee',
    LOAD_FEE: 'Load Fee',
    STOP_FEE: 'Stop Fee',
    ADJUSTMENT: 'Adjustment',
});

const LineItemRules = new Enum({
    PER_MILE: 'Per Mile',
    PER_HOUR: 'Per Hour',
    PER_DAY: 'Per Day',
    PER_LOAD: 'Per Load',
    PER_STOP: 'Per Stop',
    FRACTIONAL: 'Per Fractional Hour',
    FLAT: 'Flat Fee',
    ADJUSTMENT: 'Adjustment',
    NONE: 'None',
});

const AccountTypes = new Enum({
    SHIPPER: 'Shipper',
    CARRIER: 'Carrier',
    // PRODUCER: 'Producer',
    // RECEIVER: 'Receiver',
    FACTORING: 'Factoring',
    INSURANCE: 'Insurance',
});

const InvoiceAccountTypes = new Enum({
    SHIPPER: 'Shipper',
    CARRIER: 'Carrier',
    // PRODUCER: 'Producer',
    // RECEIVER: 'Receiver',
});

const InvoiceSourceAccountTypes = new Enum({
    SHIPPER: 'Shipper',
    CARRIER: 'Carrier',
    // PRODUCER: 'Producer',
    // RECEIVER: 'Receiver',
    FACTORING: 'Factoring Company',
    INSURANCE: 'Insurance Company',
    CREDIT: 'Credit via IRIS',
});

const InvoiceLineItemStatuses = new Enum({
    PENDING: 'Pending',
    DEBITED: 'Debited',
    CREDITED: 'Credited',
});

const InvoiceApprovalStatuses = new Enum({
    PENDING: 'Pending',
    APPROVED: 'Approved',
    NEW: 'New',
});

const InvoiceQuantityUnits = new Enum({
    PER_MILE: 'Per Mile',
    PER_HOUR: 'Per Hour',
    PER_DAY: 'Per Day',
    PER_LOAD: 'Per Load',
    PER_STOP: 'Per Stop',
    FRACTIONAL: 'Per Fractional Hour',
    FLAT: 'Flat Fee',
    ADJUSTMENT: 'Adjustment',
});

const Currencies = new Enum({
    USD: 'USD',
});

const TimeUnits = new Enum({
    MIN: 'mins',
    SECS: 'secs',
});

const DistanceUnits = new Enum({
    MI: 'mi',
});

const LengthUnits = new Enum({
    FT: 'ft',
});

const WeightUnits = new Enum({
    LB: 'lbs',
});

const VolumeUnits = new Enum({
    GAL: 'gals',
});

const TemperatureUnits = new Enum({
    F: 'F',
});

const UnitOfMeasure = new Enum({
    ENGLISH: 'English',
});

const LocationTypes = new Enum({
    WAREHOUSE: 'Warehouse',
    FARM: 'Farm',
    DISTRIBUTION_CENTER: 'Distribution Center',
    SHIP_YARD: 'Ship Yard',
    TRAIN_YARD: 'Train Yard',
    GROCERY_STORE: 'Grocery Store',
    RETAIL_STORE: 'Retail Store',
    STRIP_MALL: 'Strip Mall',
    MALL: 'Mall',
});

const Counts = new Enum({
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
});

const YesNo = new Enum({
    true: 'Yes',
    false: 'No',
});

const DayOfWeek = new Enum({
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
});

const BOLStatuses = new Enum({
    OVERAGE: 'Overage',
    SHORTAGE_LOADED_SHORT: 'Shortage - Loaded Short',
    SHORTAGE_NOT_LOADED_SHORT: 'Shortage - Not Loaded Short',
    DAMAGED: 'Damaged',
    REJECTED: 'Rejected',
    OTHER: 'Other',
});

const ClaimStatuses = new Enum({
    PENDING: 'Pending',
    CLOSED: 'Closed',
    PENDING_USDA_INSPECTION: 'Pending USDA Inspection',
    PENDING_INSURANCE_CLAIM: 'Pending Insurance Claim',
    RE_OPENED: 'Re-Opened',
});

const CMSChannels = new Enum({
    MOBILE_IN_APP: 'Mobile In App',
    WEB: 'Web',
    MOBILE_PUSH: 'Mobile Push',
    ALL: 'All',
});

const DocumentEntityTypes = new Enum({
    ACCOUNT: 'Account',
    ACCOUNT_SHIPPER: 'Shipper',
    ACCOUNT_CARRIER: 'Carrier',
    ACCOUNT_PRODUCER: 'Producer',
    ACCOUNT_RECEIVER: 'Receiver',
    DRIVER: 'Driver',
    MESSAGE: 'Load Message',
    LOAD_CANCEL: 'Load Cancellation',
    CLAIM: 'Claim',
    ASSET: 'Asset',
    LOAD: 'Load',
    STOP: 'Stop',
    TRANSPORT_UNIT: 'Transport Unit',
    USER: 'User',
    OTHER: 'Other',
});

const DocumentReviewStatuses = new Enum({
    PENDING: 'Pending',
    CLEAN: 'Clean',
    OSDR: 'OSDR',
    APPROVED: 'Approved',
});

const DocumentVisibilities = new Enum({
    ALL: 'Everyone',
    SHIPPER: 'Shipper + Staff',
    CARRIER: 'Carrier + Staff',
    PRODUCER: 'Producer + Staff',
    RECEIVER: 'Receiver + Staff',
    DRIVER: 'Driver + Carrier + Staff',
    STAFF: 'Staff',
    ADMIN: 'Admins Only',
});

const NoteVisibilities = new Enum({
    ALL: 'Everyone',
    SHIPPER: 'Shipper + Staff',
    CARRIER: 'Carrier + Staff',
    PRODUCER: 'Producer + Staff',
    RECEIVER: 'Receiver + Staff',
    DRIVER: 'Driver + Carrier + Staff',
    STAFF: 'Staff',
    ADMIN: 'Admins Only',
});

const IRISServiceTypes = new Enum({
    BROKERAGE_AND_LOCATION: 'Brokerage and Location Services',
    LOCATION_ONLY: 'Location Services Only',
    CONTRACT_RATE: 'Fixed Contract Rate',
});

const DocumentTypes = new Enum({
    ACCOUNT_LOGO: 'Account Logo',
    CLAIM: 'Claim',
    PROFILE_PHOTO: 'Profile Image',
    SHIPPER_W9: 'Shipper W9',
    SHIPPER_INSURANCE: 'Shipper Insurance',
    CARRIER_W9: 'Carrier W9',
    CARRIER_INSURANCE: 'Carrier Insurance',
    PRODUCER_W9: 'Producer W9',
    PRODUCER_INSURANCE: 'Producer Insurance',
    ASSET_PHOTO: 'Asset Photo',
    ASSET_LICENSE_PLATE: 'Asset License Plate',
    ASSET_INSURANCE: 'Asset Insurance',
    ASSET_REGISTRATION: 'Asset Registration',
    RECEIVER_W9: 'Receiver W9',
    RECEIVER_INSURANCE: 'Receiver Insurance',
    MSHA_LICENSE_FRONT: 'Front of MSHA License',
    MSHA_LICENSE_BACK: 'Back of MSHA License',
    EXPLOSIVE_CERTIFIED_LICENSE_FRONT: 'Front of Explosive Certified License',
    EXPLOSIVE_CERTIFIED_LICENSE_BACK: 'Back of Explosive Certified License',
    MC_CERTIFICATE_LICENSE_FRONT: 'Front of MC Certificate License',
    MC_CERTIFICATE_LICENSE_BACK: 'Back of MC Certificate License',
    PNEUMATIC_LICENSE_FRONT: 'Front of Pneumatic License',
    PNEUMATIC_LICENSE_BACK: 'Back of Pneumatic License',
    COMMERCIAL_DRIVERS_LICENSE_FRONT: 'Front of Commercial Drivers License',
    COMMERCIAL_DRIVERS_LICENSE_BACK: 'Back of Commercial Drivers License',
    DRIVERS_LICENSE_FRONT: 'Front of Drivers License',
    DRIVERS_LICENSE_BACK: 'Back of Drivers License',
    HAZMAT_LICENSE_FRONT: 'Front of HazMat License',
    HAZMAT_LICENSE_BACK: 'Back of HazMat License',
    BOL: 'BOL',
    LUMPER_FEE_RECEIPT: 'Lumper Fee Receipt',
    SCALE_TICKET: 'Scale Ticket',
    RECEIPT: 'Other Receipt',
    TRAILER_CONTROL_FILE: 'Trailer Control File',
    FUEL_RECEIPT: 'Fuel Receipt',
    TOLL_RECEIPT: 'Toll Receipt',
    PRICE_CONFIRMATION: 'Load Tender',
    CARRIER_WASHOUT_LOG: 'Carrier Washout Log',
    RATE_CONFIRMATION: 'Rate Confirmation',
    OTHER: 'Other Document',
});

const DocumentDisplayNames = new Enum({
    ACCOUNT_LOGO: 'Account Logo',
    CLAIM: 'Claim',
    PROFILE_PHOTO: 'Profile Image',
    SHIPPER_W9: 'Shipper W9',
    SHIPPER_INSURANCE: 'Shipper Insurance',
    CARRIER_W9: 'Carrier W9',
    CARRIER_INSURANCE: 'Carrier Insurance',
    PRODUCER_W9: 'Producer W9',
    PRODUCER_INSURANCE: 'Producer Insurance',
    ASSET_PHOTO: 'Asset Photo',
    ASSET_LICENSE_PLATE: 'Asset License Plate',
    ASSET_INSURANCE: 'Asset Insurance',
    ASSET_REGISTRATION: 'Asset Registration',
    RECEIVER_W9: 'Receiver W9',
    RECEIVER_INSURANCE: 'Receiver Insurance',
    MSHA_LICENSE_FRONT: 'Front of MSHA License',
    MSHA_LICENSE_BACK: 'Back of MSHA License',
    EXPLOSIVE_CERTIFIED_LICENSE_FRONT: 'Front of Explosive Certified License',
    EXPLOSIVE_CERTIFIED_LICENSE_BACK: 'Back of Explosive Certified License',
    MC_CERTIFICATE_LICENSE_FRONT: 'Front of MC Certificate License',
    MC_CERTIFICATE_LICENSE_BACK: 'Back of MC Certificate License',
    PNEUMATIC_LICENSE_FRONT: 'Front of Pneumatic License',
    PNEUMATIC_LICENSE_BACK: 'Back of Pneumatic License',
    COMMERCIAL_DRIVERS_LICENSE_FRONT: 'Front of Commercial Drivers License',
    COMMERCIAL_DRIVERS_LICENSE_BACK: 'Back of Commercial Drivers License',
    DRIVERS_LICENSE_FRONT: 'Front of Drivers License',
    DRIVERS_LICENSE_BACK: 'Back of Drivers License',
    HAZMAT_LICENSE_FRONT: 'Front of HazMat License',
    HAZMAT_LICENSE_BACK: 'Back of HazMat License',
    BOL: 'BOL #',
    LUMPER_FEE_RECEIPT: 'Lumper Fee Receipt',
    SCALE_TICKET: 'Scale Ticket',
    RECEIPT: 'Receipt for',
    TRAILER_CONTROL_FILE: 'Trailer Control File',
    FUEL_RECEIPT: 'Fuel Receipt',
    TOLL_RECEIPT: 'Toll Receipt',
    PRICE_CONFIRMATION: 'Load Tender',
    CARRIER_WASHOUT_LOG: 'Carrier Washout Log',
    RATE_CONFIRMATION: 'Rate Confirmation',
    OTHER: '',
});

const StopDocumentTypes = new Enum({
    BOL: 'BOL',
    SCALE_TICKET: 'Scale Ticket',
    LUMPER_FEE_RECEIPT: 'Lumper Fee Receipt',
    OTHER: 'Other Document',
});

const AccountDocumentTypes = new Enum({
    ACCOUNT_LOGO: 'Account Logo',
});

const ClaimDocumentTypes = new Enum({
    CLAIM: 'Claim',
});

const UserDocumentTypes = new Enum({
    PROFILE_PHOTO: 'Profile Image',
});

const DriverDocumentTypes = new Enum({
    MSHA_LICENSE_FRONT: 'Front of MSHA License',
    MSHA_LICENSE_BACK: 'Back of MSHA License',
    EXPLOSIVE_CERTIFIED_LICENSE_FRONT: 'Front of Explosive Certified License',
    EXPLOSIVE_CERTIFIED_LICENSE_BACK: 'Back of Explosive Certified License',
    MC_CERTIFICATE_LICENSE_FRONT: 'Front of MC Certificate License',
    MC_CERTIFICATE_LICENSE_BACK: 'Back of MC Certificate License',
    PNEUMATIC_LICENSE_FRONT: 'Front of Pneumatic License',
    PNEUMATIC_LICENSE_BACK: 'Back of Pneumatic License',
    COMMERCIAL_DRIVERS_LICENSE_FRONT: 'Front of Commercial Drivers License',
    COMMERCIAL_DRIVERS_LICENSE_BACK: 'Back of Commercial Drivers License',
    DRIVERS_LICENSE_FRONT: 'Front of Drivers License',
    DRIVERS_LICENSE_BACK: 'Back of Drivers License',
    HAZMAT_LICENSE_FRONT: 'Front of HazMat License',
    HAZMAT_LICENSE_BACK: 'Back of HazMat License',
});

const LoadDocumentTypes = new Enum({
    CARRIER_WASHOUT_LOG: 'Carrier Washout Log',
    PRICE_CONFIRMATION: 'Load Tender',
    RATE_CONFIRMATION: 'Rate Confirmation',
    TRAILER_CONTROL_FILE: 'Trailer Control File',
    FUEL_RECEIPT: 'Fuel Receipt',
    TOLL_RECEIPT: 'Toll Receipt',
    RECEIPT: 'Other Receipt',
    OTHER: 'Other Document',
});

const AssetDocumentTypes = new Enum({
    ASSET_PHOTO: 'Asset Photo',
    ASSET_LICENSE_PLATE: 'Asset License Plate',
    ASSET_INSURANCE: 'Asset Insurance',
    ASSET_REGISTRATION: 'Asset Registration',
});

const ShipperDocumentTypes = new Enum({
    SHIPPER_W9: 'Shipper W9',
    SHIPPER_INSURANCE: 'Shipper Insurance',
    SHIPPER_BROKER_AGREEMENT: 'Broker-Shipper Agreement',
    OTHER: 'Other Document',
});

const CarrierDocumentTypes = new Enum({
    CARRIER_W9: 'Carrier W9',
    CARRIER_INSURANCE: 'Carrier Insurance',
    CARRIER_BROKER_AGREEMENT: 'Broker-Carrier Agreement',
    OTHER: 'Other Document',
});

const ReceiverDocumentTypes = new Enum({
    RECEIVER_W9: 'Receiver W9',
    RECEIVER_INSURANCE: 'Receiver Insurance',
    OTHER: 'Other Document',
});

const ProducerDocumentTypes = new Enum({
    PRODUCER_W9: 'Producer W9',
    PRODUCER_INSURANCE: 'Producer Insurance',
    OTHER: 'Other Document',
});

const OtherDocumentTypes = new Enum({
    OTHER: 'Other Document',
});

const EntitlementActions = new Enum({
    CREATED: 'Created',
    READ: 'Read',
    UPDATE: 'Update',
    DELETE: 'Delete',
});

const TruckOwnerTypes = new Enum({
    INDIVIDUAL: 'Individual',
    CARRIER: 'Carrier',
});

const LoadStatuses = new Enum({
    CREATED: 'Created',
    PENDING: 'Pending',
    APPROVED: 'Shipper Approved',
    PENDING_RATE_CONFIRMATION: 'Pending Rate Confirmation',
    BOOKED: 'Booked',
    SCHEDULED: 'Scheduled',
    IN_TRANSIT: 'In Transit',
    AT_STOP: 'At Stop',
    COMPLETED: 'Completed',
    CLOSED: 'Closed',
    REOPENED: 'Re-Opened',
    CANCELLED: 'Cancelled',
});

const ShipperLoadStatuses = new Enum({
    CREATED: 'Created',
    PENDING: 'Pending',
    APPROVED: 'Shipper Approved',
    PENDING_RATE_CONFIRMATION: 'Shipper Approved',
    BOOKED: 'Booked',
    SCHEDULED: 'Booked',
    IN_TRANSIT: 'In Transit',
    AT_STOP: 'At Stop',
    COMPLETED: 'Completed',
    CLOSED: 'Completed',
    REOPENED: 'Completed',
    CANCELLED: 'Cancelled',
});

const CarrierLoadStatuses = new Enum({
    CREATED: 'Created',
    PENDING: 'Pending',
    APPROVED: 'Shipper Approved',
    PENDING_RATE_CONFIRMATION: 'Pending Rate Confirmation',
    BOOKED: 'Booked',
    SCHEDULED: 'Booked',
    IN_TRANSIT: 'In Transit',
    AT_STOP: 'At Stop',
    COMPLETED: 'Completed',
    CLOSED: 'Completed',
    REOPENED: 'Completed',
    CANCELLED: 'Cancelled',
});

const LoadDisplayStatuses = new Enum({
    CREATED: 'Created',
    PENDING: 'Pending',
    APPROVED: 'Shipper Approved',
    PENDING_RATE_CONFIRMATION: 'Pending Rate Confirmation',
    BOOKED: 'Booked',
    SCHEDULED: 'Scheduled',
    DEAD_HEADING: 'Dead Heading',
    IN_TRANSIT: 'In Transit',
    LAYOVER: 'On Layover',
    ARRIVING: 'Arriving',
    ARRIVED: 'Arrived',
    LOADING: 'Loading',
    LOADED: 'Loaded',
    UNLOADING: 'Unloading',
    UNLOADED: 'Unloaded',
    TONU: 'TONU',
    AT_STOP: 'At Stop',
    COMPLETED: 'Completed',
    CLOSED: 'Closed',
    REOPENED: 'Re-Opened',
    CANCELLED: 'Cancelled',
});

const StopStatuses = new Enum({
    PENDING: 'Pending',
    DEAD_HEADING: 'Dead Heading',
    LAYOVER: 'On Layover',
    ARRIVING: 'Arriving',
    ARRIVED: 'Arrived',
    LOADING: 'Loading',
    LOADED: 'Loaded',
    UNLOADING: 'Unloading',
    UNLOADED: 'Unloaded',
    TONU: 'TONU',
    COMPLETED: 'Completed',
});

const CancellationEntityTypes = new Enum({
    SHIPPER: 'Shipper',
    CARRIER: 'Carrier',
});

const CancellationReasons = new Enum({
    TRUCK_BREAKDOWN: 'Truck Breakdown',
    REEFER_BREAKDOWN: 'Reefer Breakdown',
    DRIVER_CANCELLATION: 'Driver Cancellation',
    OUT_OF_HOURS_SERVICE: 'Out of Hours of Service',
    CANT_COVER_LT_24_HRS: "Can't cover load less than 24 hours",
    OTHER: 'Other',
});

const AssetOutOfOrderReasons = new Enum({
    TRUCK_BREAKDOWN: 'Truck Breakdown',
    REEFER_BREAKDOWN: 'Reefer Breakdown',
    FLAT_TIRE: 'Flat Tire',
});

const AssetStatuses = new Enum({
    ON_LOAD: 'On Load',
    OFF_LOAD: 'Off Load',
    PARKED: 'Parked',
    OUT_OF_ORDER: 'Out of Order',
});

const AssetTypes = new Enum({
    TRACTOR: 'Tractor',
    TRAILER: 'Trailer',
    COMBINED: 'Truck', // Tractor-Trailer Combo (Non-Detachable)
});

const CreditReasons = new Enum({
    CUSTOMER_SERVICE: 'Customer Service/Retention',
    REFERRAL_FEE: 'Referral Fee',
});

const PaymentMethods = new Enum({
    CREDIT_CARD: 'Credit Card',
    DEBIT_CARD: 'Debit Card',
    CHECK: 'Check',
    PAYPAL: 'PayPal',
    ACCOUNT_CREDIT: 'Account Credit',
    CASH: 'Cash',
});

const PaymentStatuses = new Enum({
    PENDING: 'Pending',
    SENT: 'Sent',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    REFUNDED: 'Refunded',
    FAILED: 'Failed',
});

const NotificationSeverities = new Enum({
    NONE: 'None',
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
    CRITICAL: 'Critical',
});

const PlannedStopTypes = new Enum({
    PICK_UP: 'Pick-Up',
    DROP_OFF: 'Drop-Off',
});

const TimeZones = new Enum({
    'Africa/Abidjan': 'Africa/Abidjan',
    'Africa/Accra': 'Africa/Accra',
    'Africa/Addis_Ababa': 'Africa/Addis_Ababa',
    'Africa/Algiers': 'Africa/Algiers',
    'Africa/Asmara': 'Africa/Asmara',
    'Africa/Asmera': 'Africa/Asmera',
    'Africa/Bamako': 'Africa/Bamako',
    'Africa/Bangui': 'Africa/Bangui',
    'Africa/Banjul': 'Africa/Banjul',
    'Africa/Bissau': 'Africa/Bissau',
    'Africa/Blantyre': 'Africa/Blantyre',
    'Africa/Brazzaville': 'Africa/Brazzaville',
    'Africa/Bujumbura': 'Africa/Bujumbura',
    'Africa/Cairo': 'Africa/Cairo',
    'Africa/Casablanca': 'Africa/Casablanca',
    'Africa/Ceuta': 'Africa/Ceuta',
    'Africa/Conakry': 'Africa/Conakry',
    'Africa/Dakar': 'Africa/Dakar',
    'Africa/Dar_es_Salaam': 'Africa/Dar_es_Salaam',
    'Africa/Djibouti': 'Africa/Djibouti',
    'Africa/Douala': 'Africa/Douala',
    'Africa/El_Aaiun': 'Africa/El_Aaiun',
    'Africa/Freetown': 'Africa/Freetown',
    'Africa/Gaborone': 'Africa/Gaborone',
    'Africa/Harare': 'Africa/Harare',
    'Africa/Johannesburg': 'Africa/Johannesburg',
    'Africa/Juba': 'Africa/Juba',
    'Africa/Kampala': 'Africa/Kampala',
    'Africa/Khartoum': 'Africa/Khartoum',
    'Africa/Kigali': 'Africa/Kigali',
    'Africa/Kinshasa': 'Africa/Kinshasa',
    'Africa/Lagos': 'Africa/Lagos',
    'Africa/Libreville': 'Africa/Libreville',
    'Africa/Lome': 'Africa/Lome',
    'Africa/Luanda': 'Africa/Luanda',
    'Africa/Lubumbashi': 'Africa/Lubumbashi',
    'Africa/Lusaka': 'Africa/Lusaka',
    'Africa/Malabo': 'Africa/Malabo',
    'Africa/Maputo': 'Africa/Maputo',
    'Africa/Maseru': 'Africa/Maseru',
    'Africa/Mbabane': 'Africa/Mbabane',
    'Africa/Mogadishu': 'Africa/Mogadishu',
    'Africa/Monrovia': 'Africa/Monrovia',
    'Africa/Nairobi': 'Africa/Nairobi',
    'Africa/Ndjamena': 'Africa/Ndjamena',
    'Africa/Niamey': 'Africa/Niamey',
    'Africa/Nouakchott': 'Africa/Nouakchott',
    'Africa/Ouagadougou': 'Africa/Ouagadougou',
    'Africa/Porto-Novo': 'Africa/Porto-Novo',
    'Africa/Sao_Tome': 'Africa/Sao_Tome',
    'Africa/Timbuktu': 'Africa/Timbuktu',
    'Africa/Tripoli': 'Africa/Tripoli',
    'Africa/Tunis': 'Africa/Tunis',
    'Africa/Windhoek': 'Africa/Windhoek',
    'America/Adak': 'America/Adak',
    'America/Anchorage': 'America/Anchorage',
    'America/Anguilla': 'America/Anguilla',
    'America/Antigua': 'America/Antigua',
    'America/Araguaina': 'America/Araguaina',
    'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca': 'America/Argentina/Catamarca',
    'America/Argentina/ComodRivadavia': 'America/Argentina/ComodRivadavia',
    'America/Argentina/Cordoba': 'America/Argentina/Cordoba',
    'America/Argentina/Jujuy': 'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja': 'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza': 'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos': 'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta': 'America/Argentina/Salta',
    'America/Argentina/San_Juan': 'America/Argentina/San_Juan',
    'America/Argentina/San_Luis': 'America/Argentina/San_Luis',
    'America/Argentina/Tucuman': 'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia': 'America/Argentina/Ushuaia',
    'America/Aruba': 'America/Aruba',
    'America/Asuncion': 'America/Asuncion',
    'America/Atikokan': 'America/Atikokan',
    'America/Atka': 'America/Atka',
    'America/Bahia': 'America/Bahia',
    'America/Bahia_Banderas': 'America/Bahia_Banderas',
    'America/Barbados': 'America/Barbados',
    'America/Belem': 'America/Belem',
    'America/Belize': 'America/Belize',
    'America/Blanc-Sablon': 'America/Blanc-Sablon',
    'America/Boa_Vista': 'America/Boa_Vista',
    'America/Bogota': 'America/Bogota',
    'America/Boise': 'America/Boise',
    'America/Buenos_Aires': 'America/Buenos_Aires',
    'America/Cambridge_Bay': 'America/Cambridge_Bay',
    'America/Campo_Grande': 'America/Campo_Grande',
    'America/Cancun': 'America/Cancun',
    'America/Caracas': 'America/Caracas',
    'America/Catamarca': 'America/Catamarca',
    'America/Cayenne': 'America/Cayenne',
    'America/Cayman': 'America/Cayman',
    'America/Chicago': 'America/Chicago',
    'America/Chihuahua': 'America/Chihuahua',
    'America/Coral_Harbour': 'America/Coral_Harbour',
    'America/Cordoba': 'America/Cordoba',
    'America/Costa_Rica': 'America/Costa_Rica',
    'America/Creston': 'America/Creston',
    'America/Cuiaba': 'America/Cuiaba',
    'America/Curacao': 'America/Curacao',
    'America/Danmarkshavn': 'America/Danmarkshavn',
    'America/Dawson': 'America/Dawson',
    'America/Dawson_Creek': 'America/Dawson_Creek',
    'America/Denver': 'America/Denver',
    'America/Detroit': 'America/Detroit',
    'America/Dominica': 'America/Dominica',
    'America/Edmonton': 'America/Edmonton',
    'America/Eirunepe': 'America/Eirunepe',
    'America/El_Salvador': 'America/El_Salvador',
    'America/Ensenada': 'America/Ensenada',
    'America/Fort_Nelson': 'America/Fort_Nelson',
    'America/Fort_Wayne': 'America/Fort_Wayne',
    'America/Fortaleza': 'America/Fortaleza',
    'America/Glace_Bay': 'America/Glace_Bay',
    'America/Godthab': 'America/Godthab',
    'America/Goose_Bay': 'America/Goose_Bay',
    'America/Grand_Turk': 'America/Grand_Turk',
    'America/Grenada': 'America/Grenada',
    'America/Guadeloupe': 'America/Guadeloupe',
    'America/Guatemala': 'America/Guatemala',
    'America/Guayaquil': 'America/Guayaquil',
    'America/Guyana': 'America/Guyana',
    'America/Halifax': 'America/Halifax',
    'America/Havana': 'America/Havana',
    'America/Hermosillo': 'America/Hermosillo',
    'America/Indiana/Indianapolis': 'America/Indiana/Indianapolis',
    'America/Indiana/Knox': 'America/Indiana/Knox',
    'America/Indiana/Marengo': 'America/Indiana/Marengo',
    'America/Indiana/Petersburg': 'America/Indiana/Petersburg',
    'America/Indiana/Tell_City': 'America/Indiana/Tell_City',
    'America/Indiana/Vevay': 'America/Indiana/Vevay',
    'America/Indiana/Vincennes': 'America/Indiana/Vincennes',
    'America/Indiana/Winamac': 'America/Indiana/Winamac',
    'America/Indianapolis': 'America/Indianapolis',
    'America/Inuvik': 'America/Inuvik',
    'America/Iqaluit': 'America/Iqaluit',
    'America/Jamaica': 'America/Jamaica',
    'America/Jujuy': 'America/Jujuy',
    'America/Juneau': 'America/Juneau',
    'America/Kentucky/Louisville': 'America/Kentucky/Louisville',
    'America/Kentucky/Monticello': 'America/Kentucky/Monticello',
    'America/Knox_IN': 'America/Knox_IN',
    'America/Kralendijk': 'America/Kralendijk',
    'America/La_Paz': 'America/La_Paz',
    'America/Lima': 'America/Lima',
    'America/Los_Angeles': 'America/Los_Angeles',
    'America/Louisville': 'America/Louisville',
    'America/Lower_Princes': 'America/Lower_Princes',
    'America/Maceio': 'America/Maceio',
    'America/Managua': 'America/Managua',
    'America/Manaus': 'America/Manaus',
    'America/Marigot': 'America/Marigot',
    'America/Martinique': 'America/Martinique',
    'America/Matamoros': 'America/Matamoros',
    'America/Mazatlan': 'America/Mazatlan',
    'America/Mendoza': 'America/Mendoza',
    'America/Menominee': 'America/Menominee',
    'America/Merida': 'America/Merida',
    'America/Metlakatla': 'America/Metlakatla',
    'America/Mexico_City': 'America/Mexico_City',
    'America/Miquelon': 'America/Miquelon',
    'America/Moncton': 'America/Moncton',
    'America/Monterrey': 'America/Monterrey',
    'America/Montevideo': 'America/Montevideo',
    'America/Montreal': 'America/Montreal',
    'America/Montserrat': 'America/Montserrat',
    'America/Nassau': 'America/Nassau',
    'America/New_York': 'America/New_York',
    'America/Nipigon': 'America/Nipigon',
    'America/Nome': 'America/Nome',
    'America/Noronha': 'America/Noronha',
    'America/North_Dakota/Beulah': 'America/North_Dakota/Beulah',
    'America/North_Dakota/Center': 'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem': 'America/North_Dakota/New_Salem',
    'America/Ojinaga': 'America/Ojinaga',
    'America/Panama': 'America/Panama',
    'America/Pangnirtung': 'America/Pangnirtung',
    'America/Paramaribo': 'America/Paramaribo',
    'America/Phoenix': 'America/Phoenix',
    'America/Port-au-Prince': 'America/Port-au-Prince',
    'America/Port_of_Spain': 'America/Port_of_Spain',
    'America/Porto_Acre': 'America/Porto_Acre',
    'America/Porto_Velho': 'America/Porto_Velho',
    'America/Puerto_Rico': 'America/Puerto_Rico',
    'America/Punta_Arenas': 'America/Punta_Arenas',
    'America/Rainy_River': 'America/Rainy_River',
    'America/Rankin_Inlet': 'America/Rankin_Inlet',
    'America/Recife': 'America/Recife',
    'America/Regina': 'America/Regina',
    'America/Resolute': 'America/Resolute',
    'America/Rio_Branco': 'America/Rio_Branco',
    'America/Rosario': 'America/Rosario',
    'America/Santa_Isabel': 'America/Santa_Isabel',
    'America/Santarem': 'America/Santarem',
    'America/Santiago': 'America/Santiago',
    'America/Santo_Domingo': 'America/Santo_Domingo',
    'America/Sao_Paulo': 'America/Sao_Paulo',
    'America/Scoresbysund': 'America/Scoresbysund',
    'America/Shiprock': 'America/Shiprock',
    'America/Sitka': 'America/Sitka',
    'America/St_Barthelemy': 'America/St_Barthelemy',
    'America/St_Johns': 'America/St_Johns',
    'America/St_Kitts': 'America/St_Kitts',
    'America/St_Lucia': 'America/St_Lucia',
    'America/St_Thomas': 'America/St_Thomas',
    'America/St_Vincent': 'America/St_Vincent',
    'America/Swift_Current': 'America/Swift_Current',
    'America/Tegucigalpa': 'America/Tegucigalpa',
    'America/Thule': 'America/Thule',
    'America/Thunder_Bay': 'America/Thunder_Bay',
    'America/Tijuana': 'America/Tijuana',
    'America/Toronto': 'America/Toronto',
    'America/Tortola': 'America/Tortola',
    'America/Vancouver': 'America/Vancouver',
    'America/Virgin': 'America/Virgin',
    'America/Whitehorse': 'America/Whitehorse',
    'America/Winnipeg': 'America/Winnipeg',
    'America/Yakutat': 'America/Yakutat',
    'America/Yellowknife': 'America/Yellowknife',
    'Antarctica/Casey': 'Antarctica/Casey',
    'Antarctica/Davis': 'Antarctica/Davis',
    'Antarctica/DumontDUrville': 'Antarctica/DumontDUrville',
    'Antarctica/Macquarie': 'Antarctica/Macquarie',
    'Antarctica/Mawson': 'Antarctica/Mawson',
    'Antarctica/McMurdo': 'Antarctica/McMurdo',
    'Antarctica/Palmer': 'Antarctica/Palmer',
    'Antarctica/Rothera': 'Antarctica/Rothera',
    'Antarctica/South_Pole': 'Antarctica/South_Pole',
    'Antarctica/Syowa': 'Antarctica/Syowa',
    'Antarctica/Troll': 'Antarctica/Troll',
    'Antarctica/Vostok': 'Antarctica/Vostok',
    'Arctic/Longyearbyen': 'Arctic/Longyearbyen',
    'Asia/Aden': 'Asia/Aden',
    'Asia/Almaty': 'Asia/Almaty',
    'Asia/Amman': 'Asia/Amman',
    'Asia/Anadyr': 'Asia/Anadyr',
    'Asia/Aqtau': 'Asia/Aqtau',
    'Asia/Aqtobe': 'Asia/Aqtobe',
    'Asia/Ashgabat': 'Asia/Ashgabat',
    'Asia/Ashkhabad': 'Asia/Ashkhabad',
    'Asia/Atyrau': 'Asia/Atyrau',
    'Asia/Baghdad': 'Asia/Baghdad',
    'Asia/Bahrain': 'Asia/Bahrain',
    'Asia/Baku': 'Asia/Baku',
    'Asia/Bangkok': 'Asia/Bangkok',
    'Asia/Barnaul': 'Asia/Barnaul',
    'Asia/Beirut': 'Asia/Beirut',
    'Asia/Bishkek': 'Asia/Bishkek',
    'Asia/Brunei': 'Asia/Brunei',
    'Asia/Calcutta': 'Asia/Calcutta',
    'Asia/Chita': 'Asia/Chita',
    'Asia/Choibalsan': 'Asia/Choibalsan',
    'Asia/Chongqing': 'Asia/Chongqing',
    'Asia/Chungking': 'Asia/Chungking',
    'Asia/Colombo': 'Asia/Colombo',
    'Asia/Dacca': 'Asia/Dacca',
    'Asia/Damascus': 'Asia/Damascus',
    'Asia/Dhaka': 'Asia/Dhaka',
    'Asia/Dili': 'Asia/Dili',
    'Asia/Dubai': 'Asia/Dubai',
    'Asia/Dushanbe': 'Asia/Dushanbe',
    'Asia/Famagusta': 'Asia/Famagusta',
    'Asia/Gaza': 'Asia/Gaza',
    'Asia/Harbin': 'Asia/Harbin',
    'Asia/Hebron': 'Asia/Hebron',
    'Asia/Ho_Chi_Minh': 'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong': 'Asia/Hong_Kong',
    'Asia/Hovd': 'Asia/Hovd',
    'Asia/Irkutsk': 'Asia/Irkutsk',
    'Asia/Istanbul': 'Asia/Istanbul',
    'Asia/Jakarta': 'Asia/Jakarta',
    'Asia/Jayapura': 'Asia/Jayapura',
    'Asia/Jerusalem': 'Asia/Jerusalem',
    'Asia/Kabul': 'Asia/Kabul',
    'Asia/Kamchatka': 'Asia/Kamchatka',
    'Asia/Karachi': 'Asia/Karachi',
    'Asia/Kashgar': 'Asia/Kashgar',
    'Asia/Kathmandu': 'Asia/Kathmandu',
    'Asia/Katmandu': 'Asia/Katmandu',
    'Asia/Khandyga': 'Asia/Khandyga',
    'Asia/Kolkata': 'Asia/Kolkata',
    'Asia/Krasnoyarsk': 'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur': 'Asia/Kuala_Lumpur',
    'Asia/Kuching': 'Asia/Kuching',
    'Asia/Kuwait': 'Asia/Kuwait',
    'Asia/Macao': 'Asia/Macao',
    'Asia/Macau': 'Asia/Macau',
    'Asia/Magadan': 'Asia/Magadan',
    'Asia/Makassar': 'Asia/Makassar',
    'Asia/Manila': 'Asia/Manila',
    'Asia/Muscat': 'Asia/Muscat',
    'Asia/Nicosia': 'Asia/Nicosia',
    'Asia/Novokuznetsk': 'Asia/Novokuznetsk',
    'Asia/Novosibirsk': 'Asia/Novosibirsk',
    'Asia/Omsk': 'Asia/Omsk',
    'Asia/Oral': 'Asia/Oral',
    'Asia/Phnom_Penh': 'Asia/Phnom_Penh',
    'Asia/Pontianak': 'Asia/Pontianak',
    'Asia/Pyongyang': 'Asia/Pyongyang',
    'Asia/Qatar': 'Asia/Qatar',
    'Asia/Qostanay': 'Asia/Qostanay',
    'Asia/Qyzylorda': 'Asia/Qyzylorda',
    'Asia/Rangoon': 'Asia/Rangoon',
    'Asia/Riyadh': 'Asia/Riyadh',
    'Asia/Saigon': 'Asia/Saigon',
    'Asia/Sakhalin': 'Asia/Sakhalin',
    'Asia/Samarkand': 'Asia/Samarkand',
    'Asia/Seoul': 'Asia/Seoul',
    'Asia/Shanghai': 'Asia/Shanghai',
    'Asia/Singapore': 'Asia/Singapore',
    'Asia/Srednekolymsk': 'Asia/Srednekolymsk',
    'Asia/Taipei': 'Asia/Taipei',
    'Asia/Tashkent': 'Asia/Tashkent',
    'Asia/Tbilisi': 'Asia/Tbilisi',
    'Asia/Tehran': 'Asia/Tehran',
    'Asia/Tel_Aviv': 'Asia/Tel_Aviv',
    'Asia/Thimbu': 'Asia/Thimbu',
    'Asia/Thimphu': 'Asia/Thimphu',
    'Asia/Tokyo': 'Asia/Tokyo',
    'Asia/Tomsk': 'Asia/Tomsk',
    'Asia/Ujung_Pandang': 'Asia/Ujung_Pandang',
    'Asia/Ulaanbaatar': 'Asia/Ulaanbaatar',
    'Asia/Ulan_Bator': 'Asia/Ulan_Bator',
    'Asia/Urumqi': 'Asia/Urumqi',
    'Asia/Ust-Nera': 'Asia/Ust-Nera',
    'Asia/Vientiane': 'Asia/Vientiane',
    'Asia/Vladivostok': 'Asia/Vladivostok',
    'Asia/Yakutsk': 'Asia/Yakutsk',
    'Asia/Yangon': 'Asia/Yangon',
    'Asia/Yekaterinburg': 'Asia/Yekaterinburg',
    'Asia/Yerevan': 'Asia/Yerevan',
    'Atlantic/Azores': 'Atlantic/Azores',
    'Atlantic/Bermuda': 'Atlantic/Bermuda',
    'Atlantic/Canary': 'Atlantic/Canary',
    'Atlantic/Cape_Verde': 'Atlantic/Cape_Verde',
    'Atlantic/Faeroe': 'Atlantic/Faeroe',
    'Atlantic/Faroe': 'Atlantic/Faroe',
    'Atlantic/Jan_Mayen': 'Atlantic/Jan_Mayen',
    'Atlantic/Madeira': 'Atlantic/Madeira',
    'Atlantic/Reykjavik': 'Atlantic/Reykjavik',
    'Atlantic/South_Georgia': 'Atlantic/South_Georgia',
    'Atlantic/St_Helena': 'Atlantic/St_Helena',
    'Atlantic/Stanley': 'Atlantic/Stanley',
    'Australia/ACT': 'Australia/ACT',
    'Australia/Adelaide': 'Australia/Adelaide',
    'Australia/Brisbane': 'Australia/Brisbane',
    'Australia/Broken_Hill': 'Australia/Broken_Hill',
    'Australia/Canberra': 'Australia/Canberra',
    'Australia/Currie': 'Australia/Currie',
    'Australia/Darwin': 'Australia/Darwin',
    'Australia/Eucla': 'Australia/Eucla',
    'Australia/Hobart': 'Australia/Hobart',
    'Australia/LHI': 'Australia/LHI',
    'Australia/Lindeman': 'Australia/Lindeman',
    'Australia/Lord_Howe': 'Australia/Lord_Howe',
    'Australia/Melbourne': 'Australia/Melbourne',
    'Australia/NSW': 'Australia/NSW',
    'Australia/North': 'Australia/North',
    'Australia/Perth': 'Australia/Perth',
    'Australia/Queensland': 'Australia/Queensland',
    'Australia/South': 'Australia/South',
    'Australia/Sydney': 'Australia/Sydney',
    'Australia/Tasmania': 'Australia/Tasmania',
    'Australia/Victoria': 'Australia/Victoria',
    'Australia/West': 'Australia/West',
    'Australia/Yancowinna': 'Australia/Yancowinna',
    'Brazil/Acre': 'Brazil/Acre',
    'Brazil/DeNoronha': 'Brazil/DeNoronha',
    'Brazil/East': 'Brazil/East',
    'Brazil/West': 'Brazil/West',
    'CET': 'CET',
    'CST6CDT': 'CST6CDT',
    'Canada/Atlantic': 'Canada/Atlantic',
    'Canada/Central': 'Canada/Central',
    'Canada/Eastern': 'Canada/Eastern',
    'Canada/Mountain': 'Canada/Mountain',
    'Canada/Newfoundland': 'Canada/Newfoundland',
    'Canada/Pacific': 'Canada/Pacific',
    'Canada/Saskatchewan': 'Canada/Saskatchewan',
    'Canada/Yukon': 'Canada/Yukon',
    'Chile/Continental': 'Chile/Continental',
    'Chile/EasterIsland': 'Chile/EasterIsland',
    'Cuba': 'Cuba',
    'EET': 'EET',
    'EST': 'EST',
    'EST5EDT': 'EST5EDT',
    'Egypt': 'Egypt',
    'Eire': 'Eire',
    'Etc/GMT': 'Etc/GMT',
    'Etc/GMT+0': 'Etc/GMT+0',
    'Etc/GMT+1': 'Etc/GMT+1',
    'Etc/GMT+10': 'Etc/GMT+10',
    'Etc/GMT+11': 'Etc/GMT+11',
    'Etc/GMT+12': 'Etc/GMT+12',
    'Etc/GMT+2': 'Etc/GMT+2',
    'Etc/GMT+3': 'Etc/GMT+3',
    'Etc/GMT+4': 'Etc/GMT+4',
    'Etc/GMT+5': 'Etc/GMT+5',
    'Etc/GMT+6': 'Etc/GMT+6',
    'Etc/GMT+7': 'Etc/GMT+7',
    'Etc/GMT+8': 'Etc/GMT+8',
    'Etc/GMT+9': 'Etc/GMT+9',
    'Etc/GMT-0': 'Etc/GMT-0',
    'Etc/GMT-1': 'Etc/GMT-1',
    'Etc/GMT-10': 'Etc/GMT-10',
    'Etc/GMT-11': 'Etc/GMT-11',
    'Etc/GMT-12': 'Etc/GMT-12',
    'Etc/GMT-13': 'Etc/GMT-13',
    'Etc/GMT-14': 'Etc/GMT-14',
    'Etc/GMT-2': 'Etc/GMT-2',
    'Etc/GMT-3': 'Etc/GMT-3',
    'Etc/GMT-4': 'Etc/GMT-4',
    'Etc/GMT-5': 'Etc/GMT-5',
    'Etc/GMT-6': 'Etc/GMT-6',
    'Etc/GMT-7': 'Etc/GMT-7',
    'Etc/GMT-8': 'Etc/GMT-8',
    'Etc/GMT-9': 'Etc/GMT-9',
    'Etc/GMT0': 'Etc/GMT0',
    'Etc/Greenwich': 'Etc/Greenwich',
    'Etc/UCT': 'Etc/UCT',
    'Etc/UTC': 'Etc/UTC',
    'Etc/Universal': 'Etc/Universal',
    'Etc/Zulu': 'Etc/Zulu',
    'Europe/Amsterdam': 'Europe/Amsterdam',
    'Europe/Andorra': 'Europe/Andorra',
    'Europe/Astrakhan': 'Europe/Astrakhan',
    'Europe/Athens': 'Europe/Athens',
    'Europe/Belfast': 'Europe/Belfast',
    'Europe/Belgrade': 'Europe/Belgrade',
    'Europe/Berlin': 'Europe/Berlin',
    'Europe/Bratislava': 'Europe/Bratislava',
    'Europe/Brussels': 'Europe/Brussels',
    'Europe/Bucharest': 'Europe/Bucharest',
    'Europe/Budapest': 'Europe/Budapest',
    'Europe/Busingen': 'Europe/Busingen',
    'Europe/Chisinau': 'Europe/Chisinau',
    'Europe/Copenhagen': 'Europe/Copenhagen',
    'Europe/Dublin': 'Europe/Dublin',
    'Europe/Gibraltar': 'Europe/Gibraltar',
    'Europe/Guernsey': 'Europe/Guernsey',
    'Europe/Helsinki': 'Europe/Helsinki',
    'Europe/Isle_of_Man': 'Europe/Isle_of_Man',
    'Europe/Istanbul': 'Europe/Istanbul',
    'Europe/Jersey': 'Europe/Jersey',
    'Europe/Kaliningrad': 'Europe/Kaliningrad',
    'Europe/Kiev': 'Europe/Kiev',
    'Europe/Kirov': 'Europe/Kirov',
    'Europe/Lisbon': 'Europe/Lisbon',
    'Europe/Ljubljana': 'Europe/Ljubljana',
    'Europe/London': 'Europe/London',
    'Europe/Luxembourg': 'Europe/Luxembourg',
    'Europe/Madrid': 'Europe/Madrid',
    'Europe/Malta': 'Europe/Malta',
    'Europe/Mariehamn': 'Europe/Mariehamn',
    'Europe/Minsk': 'Europe/Minsk',
    'Europe/Monaco': 'Europe/Monaco',
    'Europe/Moscow': 'Europe/Moscow',
    'Europe/Nicosia': 'Europe/Nicosia',
    'Europe/Oslo': 'Europe/Oslo',
    'Europe/Paris': 'Europe/Paris',
    'Europe/Podgorica': 'Europe/Podgorica',
    'Europe/Prague': 'Europe/Prague',
    'Europe/Riga': 'Europe/Riga',
    'Europe/Rome': 'Europe/Rome',
    'Europe/Samara': 'Europe/Samara',
    'Europe/San_Marino': 'Europe/San_Marino',
    'Europe/Sarajevo': 'Europe/Sarajevo',
    'Europe/Saratov': 'Europe/Saratov',
    'Europe/Simferopol': 'Europe/Simferopol',
    'Europe/Skopje': 'Europe/Skopje',
    'Europe/Sofia': 'Europe/Sofia',
    'Europe/Stockholm': 'Europe/Stockholm',
    'Europe/Tallinn': 'Europe/Tallinn',
    'Europe/Tirane': 'Europe/Tirane',
    'Europe/Tiraspol': 'Europe/Tiraspol',
    'Europe/Ulyanovsk': 'Europe/Ulyanovsk',
    'Europe/Uzhgorod': 'Europe/Uzhgorod',
    'Europe/Vaduz': 'Europe/Vaduz',
    'Europe/Vatican': 'Europe/Vatican',
    'Europe/Vienna': 'Europe/Vienna',
    'Europe/Vilnius': 'Europe/Vilnius',
    'Europe/Volgograd': 'Europe/Volgograd',
    'Europe/Warsaw': 'Europe/Warsaw',
    'Europe/Zagreb': 'Europe/Zagreb',
    'Europe/Zaporozhye': 'Europe/Zaporozhye',
    'Europe/Zurich': 'Europe/Zurich',
    'GB': 'GB',
    'GB-Eire': 'GB-Eire',
    'GMT': 'GMT',
    'GMT+0': 'GMT+0',
    'GMT-0': 'GMT-0',
    'GMT0': 'GMT0',
    'Greenwich': 'Greenwich',
    'HST': 'HST',
    'Hongkong': 'Hongkong',
    'Iceland': 'Iceland',
    'Indian/Antananarivo': 'Indian/Antananarivo',
    'Indian/Chagos': 'Indian/Chagos',
    'Indian/Christmas': 'Indian/Christmas',
    'Indian/Cocos': 'Indian/Cocos',
    'Indian/Comoro': 'Indian/Comoro',
    'Indian/Kerguelen': 'Indian/Kerguelen',
    'Indian/Mahe': 'Indian/Mahe',
    'Indian/Maldives': 'Indian/Maldives',
    'Indian/Mauritius': 'Indian/Mauritius',
    'Indian/Mayotte': 'Indian/Mayotte',
    'Indian/Reunion': 'Indian/Reunion',
    'Iran': 'Iran',
    'Israel': 'Israel',
    'Jamaica': 'Jamaica',
    'Japan': 'Japan',
    'Kwajalein': 'Kwajalein',
    'Libya': 'Libya',
    'MET': 'MET',
    'MST': 'MST',
    'MST7MDT': 'MST7MDT',
    'Mexico/BajaNorte': 'Mexico/BajaNorte',
    'Mexico/BajaSur': 'Mexico/BajaSur',
    'Mexico/General': 'Mexico/General',
    'NZ': 'NZ',
    'NZ-CHAT': 'NZ-CHAT',
    'Navajo': 'Navajo',
    'PRC': 'PRC',
    'PST8PDT': 'PST8PDT',
    'Pacific/Apia': 'Pacific/Apia',
    'Pacific/Auckland': 'Pacific/Auckland',
    'Pacific/Bougainville': 'Pacific/Bougainville',
    'Pacific/Chatham': 'Pacific/Chatham',
    'Pacific/Chuuk': 'Pacific/Chuuk',
    'Pacific/Easter': 'Pacific/Easter',
    'Pacific/Efate': 'Pacific/Efate',
    'Pacific/Enderbury': 'Pacific/Enderbury',
    'Pacific/Fakaofo': 'Pacific/Fakaofo',
    'Pacific/Fiji': 'Pacific/Fiji',
    'Pacific/Funafuti': 'Pacific/Funafuti',
    'Pacific/Galapagos': 'Pacific/Galapagos',
    'Pacific/Gambier': 'Pacific/Gambier',
    'Pacific/Guadalcanal': 'Pacific/Guadalcanal',
    'Pacific/Guam': 'Pacific/Guam',
    'Pacific/Honolulu': 'Pacific/Honolulu',
    'Pacific/Johnston': 'Pacific/Johnston',
    'Pacific/Kiritimati': 'Pacific/Kiritimati',
    'Pacific/Kosrae': 'Pacific/Kosrae',
    'Pacific/Kwajalein': 'Pacific/Kwajalein',
    'Pacific/Majuro': 'Pacific/Majuro',
    'Pacific/Marquesas': 'Pacific/Marquesas',
    'Pacific/Midway': 'Pacific/Midway',
    'Pacific/Nauru': 'Pacific/Nauru',
    'Pacific/Niue': 'Pacific/Niue',
    'Pacific/Norfolk': 'Pacific/Norfolk',
    'Pacific/Noumea': 'Pacific/Noumea',
    'Pacific/Pago_Pago': 'Pacific/Pago_Pago',
    'Pacific/Palau': 'Pacific/Palau',
    'Pacific/Pitcairn': 'Pacific/Pitcairn',
    'Pacific/Pohnpei': 'Pacific/Pohnpei',
    'Pacific/Ponape': 'Pacific/Ponape',
    'Pacific/Port_Moresby': 'Pacific/Port_Moresby',
    'Pacific/Rarotonga': 'Pacific/Rarotonga',
    'Pacific/Saipan': 'Pacific/Saipan',
    'Pacific/Samoa': 'Pacific/Samoa',
    'Pacific/Tahiti': 'Pacific/Tahiti',
    'Pacific/Tarawa': 'Pacific/Tarawa',
    'Pacific/Tongatapu': 'Pacific/Tongatapu',
    'Pacific/Truk': 'Pacific/Truk',
    'Pacific/Wake': 'Pacific/Wake',
    'Pacific/Wallis': 'Pacific/Wallis',
    'Pacific/Yap': 'Pacific/Yap',
    'Poland': 'Poland',
    'Portugal': 'Portugal',
    'ROC': 'ROC',
    'ROK': 'ROK',
    'Singapore': 'Singapore',
    'Turkey': 'Turkey',
    'UCT': 'UCT',
    'US/Alaska': 'US/Alaska',
    'US/Aleutian': 'US/Aleutian',
    'US/Arizona': 'US/Arizona',
    'US/Central': 'US/Central',
    'US/East-Indiana': 'US/East-Indiana',
    'US/Eastern': 'US/Eastern',
    'US/Hawaii': 'US/Hawaii',
    'US/Indiana-Starke': 'US/Indiana-Starke',
    'US/Michigan': 'US/Michigan',
    'US/Mountain': 'US/Mountain',
    'US/Pacific': 'US/Pacific',
    'US/Samoa': 'US/Samoa',
    'UTC': 'UTC',
    'Universal': 'Universal',
    'W-SU': 'W-SU',
    'WET': 'WET',
    'Zulu': 'Zulu',
});

const USATimeZones = new Enum({
    'US/Alaska': 'US/Alaska',
    'US/Aleutian': 'US/Aleutian',
    'US/Arizona': 'US/Arizona',
    'US/Central': 'US/Central',
    'US/East-Indiana': 'US/East-Indiana',
    'US/Eastern': 'US/Eastern',
    'US/Hawaii': 'US/Hawaii',
    'US/Indiana-Starke': 'US/Indiana-Starke',
    'US/Michigan': 'US/Michigan',
    'US/Mountain': 'US/Mountain',
    'US/Pacific': 'US/Pacific',
    'US/Samoa': 'US/Samoa',
});

const NoteEntityTypes = new Enum({
    LOAD: 'Load',
    STOP: 'Stop',
    TRANSACTION: 'Transaction',
    INVOICE: 'Invoice',
    ACCOUNT: 'Account',
    ACCOUNT_SHIPPER: 'Shipper',
    ACCOUNT_CARRIER: 'Carrier',
    ACCOUNT_PRODUCER: 'Producer',
    ACCOUNT_RECEIVER: 'Receiver',
    DRIVER: 'Driver',    
    CLAIM: 'Claim',
    LOAD_CANCEL: 'Load Cancellation',
    ASSET: 'Asset',
    USER: 'User',
    LOAD_LANE: 'Load Lane',
    LOCATION: 'Location',
});

const MobileModeEntityTypes = new Enum({
    SHIPPER: 'Shipper',
    CARRIER: 'Carrier',
    PRODUCER: 'Producer',
    RECEIVER: 'Receiver',
    DRIVER: 'Driver',
    STAFF: 'Staff',
});

export default {
    AppointmentTypes,
    UnitPackaging,
    BulkPackaging,
    StopTypes,
    LineItemNames,
    LineItemTypes,
    LineItemRules,
    AccountTypes,
    Currencies,
    Counts,
    Accessorials,
    StopFees,
    LoadRequirements,
    EquipmentSpecifics,
    LoadFees,
    IRISFees,
    Adjustments,
    YesNo,
    LocationTypes,
    DayOfWeek,
    TimeUnits,
    DistanceUnits,
    WeightUnits,
    LengthUnits,
    VolumeUnits,
    ClaimStatuses,
    BOLStatuses,
    CMSChannels,
    DocumentEntityTypes,
    DocumentReviewStatuses,
    DocumentVisibilities,
    IRISServiceTypes,
    DocumentTypes,
    EntitlementActions,
    InvoiceAccountTypes,
    InvoiceSourceAccountTypes,
    InvoiceLineItemStatuses,
    InvoiceApprovalStatuses,
    InvoiceQuantityUnits,
    TemperatureUnits,
    UnitOfMeasure,
    TruckOwnerTypes,
    LoadStatuses,
    StopStatuses,
    CancellationEntityTypes,
    CancellationReasons,
    AssetOutOfOrderReasons,
    AssetStatuses,
    AssetTypes,
    CreditReasons,
    PaymentMethods,
    PaymentStatuses,
    NotificationSeverities,
    PlannedStopTypes,
    StopDocumentTypes,
    AccountDocumentTypes,
    ClaimDocumentTypes,
    UserDocumentTypes,
    DriverDocumentTypes,
    LoadDocumentTypes,
    AssetDocumentTypes,
    ShipperDocumentTypes,
    CarrierDocumentTypes,
    ReceiverDocumentTypes,
    ProducerDocumentTypes,
    OtherDocumentTypes,
    DocumentDisplayNames,
    TimeZones,
    USATimeZones,
    NoteEntityTypes,
    MobileModeEntityTypes,
    LoadDisplayStatuses,
    NoteVisibilities,
    ShipperLoadStatuses,
    CarrierLoadStatuses
}