import React from "react";
import { Row, Col, Card } from 'antd';
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";

const Investors = (props) => {
	return (
		<div>
			<Row>
				<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
					<SectionHeader size={2} title="INVESTOR RELATIONS" />
				</Col>
			</Row>
			<Row>
				<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
					<Card bordered={false}>
						Investor relations...
					</Card>
				</Col>
			</Row>
		</div>
	);
}

export default withRouter(Investors);
