import React from "react";
import { Col, Row, Alert, Tooltip } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { InfoCircleOutlined } from "@ant-design/icons";
import { isBooleanTrue, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";

const FormItem = ({ label, labelCol = {}, wrapperCol = {}, format = 'vertical', required = false, noColon = false, as, render, rules, name, defaultValue, onChange, disabled = false, help = null, style = null, tooltip: tooltipText }) => {
    const methods = useFormContext();

    const controllerProps = {
        name: name
    };

    if (as !== undefined) {
        controllerProps.as = as;
    }

    if (render !== undefined) {
        controllerProps.render = render;
    }

    if (rules !== undefined) {
        controllerProps.rules = rules;
    }

    if (defaultValue !== undefined) {
        controllerProps.defaultValue = defaultValue;
    }

    if (onChange !== undefined) {
        controllerProps.onChange = onChange;
    }

    if (disabled !== undefined) {
        controllerProps.disabled = disabled;
    }

    //console.log(controllerProps);

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    const getTooltip = (text) => {
        if (isStringNotEmpty(text)) {
            return (
                <Tooltip placement="top" title={text}>
                    <InfoCircleOutlined style={{ color: '#23BEBB', marginLeft: 8 }} />
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    if (format === 'vertical') {
        return (
            <>
                {isStringNotEmpty(label) ? (
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0 }}>
                        <Col span={24}>{isBooleanTrue(required) ? '* ' : ''}{label}{getTooltip(tooltipText)}{isBooleanTrue(noColon) ? '' : ':'}</Col>
                    </Row>
                ) : null}
                <Row gutter={[8, 8]}>
                    <Col span={24} style={style}>
                        <Controller control={methods.control} {...controllerProps} />
                    </Col>
                </Row>
                {isNotNullOrUndefined(help) ? (
                    <Row gutter={[8, 8]}>
                        <Col span={24}>{help}</Col>
                    </Row>
                ) : null}
                {getError(methods.errors, name) !== undefined ? (
                    <ErrorMessage
                        errors={methods.errors}
                        name={name}
                        render={({ message, messages }) => {
                            if (isNotNullOrUndefined(messages)) {
                                return Object.entries(messages).map(([type, message]) => (<Alert key={`${name}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                            } else if (isStringNotEmpty(message)) {
                                return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                            } else {
                                return null;
                            }
                        }}
                    />
                ) : null}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <Row gutter={[8, 8]}>
                    {isStringNotEmpty(label) ? (
                        <Col {...labelCol} style={{ textAlign: 'right' }}>{isBooleanTrue(required) ? '* ' : ''}{label}{getTooltip(tooltipText)}{isBooleanTrue(noColon) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol}>
                        <Controller control={methods.control} {...controllerProps} />
                    </Col>
                </Row>
                {isNotNullOrUndefined(help) ? (
                    <Row gutter={[8, 8]}>
                        <Col {...labelCol}></Col>
                        <Col {...wrapperCol}>{help}</Col>
                    </Row>
                ) : null}
                {getError(methods.errors, name) !== undefined ? (
                    <Row gutter={[8, 8]}>
                        <Col {...labelCol}></Col>
                        <Col {...wrapperCol}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                ) : null}
            </>
        );
    } else {
        return null;
    }
};

export default FormItem;