import React from "react";
import CookiePolicy from "../../components/CookiePolicy/CookiePolicy";
import { withRouter } from "react-router-dom";

const Cookies = (props) => {
	return (
		<CookiePolicy />
	);
};

export default withRouter(Cookies);
