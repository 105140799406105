import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Select, Spin, Alert, Result, Switch } from 'antd';
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { useForm, FormProvider } from "react-hook-form";
import states from "../../shared/states.json";
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import FormItemDouble from '../../components/FormItemDouble/FormItemDouble';
import FormButtons from "../../components/FormButtons/FormButtons";
import Fieldset from "../../components/FormFieldset/FormFieldset";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { isObjectNotEmpty, removeEmpty } from "../../shared/objectUtils";
import logger from "../../shared/logger";

const { Title, Paragraph } = Typography;

const baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;

const Shippers = (props) => {
	//#region useForms

	const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

	//#endregion
	//#region useStates

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	//#endregion
	//#region onSubmit

	const onSubmit = (data) => {
		data.entityTypes = ["SHIPPER"];
		// console.log(data);

		const cleanedData = removeEmpty(data);
		// console.log(cleanedData);

		if (isObjectNotEmpty(cleanedData)) {
			setError(null);
			setIsLoading(true);
			axios({
				method: 'post',
				url: `${baseURL}/accountRegistrations`,
				data: cleanedData,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			}).then((res) => {
				// console.log(res);
				// console.log(res.data);
				setIsLoading(false);
				setIsFormSubmitted(true);
				window.scrollTo(0, 0);
			}).catch((error) => {
				logger.logErrorEvent('Shippers.js', error, error.message, true);
				setIsLoading(false);
				setError(error.message);
				setIsFormSubmitted(false);
			});
		}
	};

	//#endregion
	//#region styles

	const formItemLayout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};

	const formItemLayoutHorizontal = {
		labelCol: { span: 16 },
		wrapperCol: { span: 8 },
	};

	const formItemLayoutDouble = {
		labelCol1: { span: 16 },
		wrapperCol1: { span: 16 },
		labelCol2: { span: 8 },
		wrapperCol2: { span: 8 },
	};

	const formItemLayoutDouble2 = {
		labelCol1: { span: 24 },
		wrapperCol1: { span: 12 },
		labelCol2: { span: 0 },
		wrapperCol2: { span: 12 },
	};

	//#endregion

	if (isFormSubmitted === true) {
		return (
			<div id="Confirmation">
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<SectionHeader size={2} title="SHIPPER APPLICATION" />
					</Col>
				</Row>
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<Result
							status="success"
							title="Thank you for your application!"
							subTitle="We will be in touch with you soon!"
						/>
					</Col>
				</Row>
			</div>
		);
	} else {
		return (
			<Spin spinning={isLoading === true && error === null}>
				<div>
					<Row>
						<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
							<SectionHeader size={2} title="SHIPPER APPLICATION" />
						</Col>
					</Row>
					<Row>
						<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
							<Card bordered={false}>
								<Paragraph>Thank you for your interest in becoming a Shipper with IRIS Freight.</Paragraph>
								<Paragraph>Please fill out the form below and a member of our team will be in touch with you regarding next steps!</Paragraph>

								<Title level={4}>Step 1: Fill out the form below.</Title>
								<FormProvider {...methods}>
									<Form className="login-form" onSubmit={methods.handleSubmit(onSubmit)}>
										<Fieldset legend="Company Contact Information (* indicates a required field)">
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Company Name" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Company Name"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="name"
													/>
													<FormItemDouble {...formItemLayoutDouble2} label1="Contact Name" required1 required2 format="vertical"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="First Name"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules1={{ required: 'Required Field' }}
														name1="pointOfContact.firstName"
														render2={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Last Name"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules2={{ required: 'Required Field' }}
														name2="pointOfContact.lastName"
													/>
													<FormItem {...formItemLayout} label="Email Address" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Email Address"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{
															required: 'Required Field',
															minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
															pattern: {
																value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
																message: "Please enter a valid email address"
															}
														}}
														name="email"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Federal Id/EIN" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="EIN"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="ein"
													/>
													<FormItemDouble {...formItemLayoutDouble} label1="Day Time Phone #" required1 format="vertical"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Phone #"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules1={{
															required: 'Required Field',
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="phone"
														render2={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Ext"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules2={{ required: false }}
														name2="phoneExt"
													/>
													<FormItemDouble {...formItemLayoutDouble} label1="After Hours Phone #" required1 format="vertical"
														render1={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Phone #"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules1={{
															required: 'Required Field',
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="afterHoursPhone"
														render2={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Ext"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules2={{ required: false }}
														name2="afterHoursPhoneExt"
													/>
													<FormItem {...formItemLayout} label="Fax #" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Fax #"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="fax"
													/>
												</Col>
											</Row>
										</Fieldset>
										<Fieldset legend="Company Address (* indicates a required field)">
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 1" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Street Address 1"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.streetAddress1"
													/>
													<FormItem {...formItemLayout} label="City" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="City"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.city"
													/>
													<FormItem {...formItemLayout} label="Postal Code" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Postal Code"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.postalCode"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 2" format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Input
																placeholder="Street Address 2"
																onBlur={onBlur}
																onChange={e => { onChange(e.target.value); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: false }}
														name="companyAddress.streetAddress2"
													/>
													<FormItem {...formItemLayout} label="State" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Select
																placeholder="Please Select a State"
																allowClear={true}
																style={{ width: '100%' }}
																virtual={false}
																showSearch={true}
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																options={states.map(s => { return { label: s.name, value: s.abbreviation } })}
																onBlur={onBlur}
																onChange={(selected) => { onChange(selected); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.state"
													/>
													<FormItem {...formItemLayout} label="Country" required format="vertical"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Select
																placeholder="Please Select a Country"
																allowClear={true}
																style={{ width: '100%' }}
																virtual={false}
																showSearch={true}
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																options={[{ label: "United States", value: "USA" }]}
																onBlur={onBlur}
																onChange={(selected) => { onChange(selected); }}
																value={value}
																name={name}
																ref={ref}
															/>
														)}
														rules={{ required: 'Required Field' }}
														name="companyAddress.country"
													/>
												</Col>
											</Row>
										</Fieldset>
										<Fieldset legend="What are your equipment needs based on the commodities you ship?">
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<h4 style={{ textAlign: 'center' }}>Reefers</h4>
													<FormItem {...formItemLayoutHorizontal} label="Do you need 48' Reefers?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.has48Reefer"
														defaultValue={false}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need 53' Reefers?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.has53Reefer"
														defaultValue={false}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need to ship frozen?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.hasReeferFrozen"
														defaultValue={false}
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
													<FormItem {...formItemLayoutHorizontal} label="Do you need 48' Dry Vans?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.has48DryVan"
														defaultValue={false}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need 53' Dry Vans?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.has53DryVan"
														defaultValue={false}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the dry vans?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.hasDryVanLiftGates"
														defaultValue={false}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need roller beds for the dry vans?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.hasDryVanRollerBed"
														defaultValue={false}
													/>
													<h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
													<FormItem {...formItemLayoutHorizontal} label="Do you need to ship over dimensional/overweight loads?" noColon format="horizontal"
														render={({ onChange, onBlur, value, name, ref }) => (
															<Switch
																checkedChildren="Yes"
																unCheckedChildren="No"
																onBlur={onBlur}
																onChange={(checked, event) => { onChange(checked); }}
																checked={value}
																name={name}
																ref={ref}
															/>
														)}
														name="assetNeeds.hasOverSized"
														defaultValue={false}
													/>
												</Col>
											</Row>
										</Fieldset>
										{error && <Alert message={error} type="error" />}
										<FormButtons
											containerStyle={{
												right: 0,
												width: '100%',
												borderTop: '1px solid #e9e9e9',
												padding: '10px 16px',
												background: '#fff',
												textAlign: 'right',
											}}
											submitDisabled={isLoading === true && error === null}
											submitText="Submit"
										/>
									</Form>
								</FormProvider>
							</Card>
						</Col>
					</Row>
				</div>
			</Spin>
		);
	}
}

export default withRouter(Shippers);
