import React from "react";
import { Typography, Divider } from 'antd';

const { Title } = Typography;

const SectionHeader = ({ size = 3, title }) => {
    return (
        <>
            <Typography>
                <Title level={size} className="section-header">{title}</Title>
            </Typography>
            <Divider className="section-divider" />
        </>
    )
}

export default SectionHeader;