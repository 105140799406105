import React from "react";
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";
import { withRouter } from "react-router-dom";

const Privacy = (props) => {
	return (
		<PrivacyPolicy />
	);
};

export default withRouter(Privacy);
