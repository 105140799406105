import React from "react";
import { Layout, Button, Row, Col } from 'antd';
import classes from './IRISFooter.module.scss';
import irisLogoSmall from '../../assets/img/full-logo.png';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import { PhoneOutlined } from '@ant-design/icons';

const { Footer } = Layout;
const version = process.env.REACT_APP_VERSION

const IRISFooter = () => {
    return (
        <Footer style={{ textAlign: 'center', minWidth: '300px' }} className={classes.footer}>
            <section style={{ paddingBottom: 20 }}>
                <Row>
                    <Col span={6} className={classes.footerSection}>
                        <div className={classes.sectionHeader}>
                            <img src={irisLogoSmall} className={classes.logo} alt="IRIS" />
                        </div>
                        <DataAddressRow
							address={{
								streetAddress1: "4445 North 36th Street",
								streetAddress2: "Unit 312",
								city: "Phoenix",
								state: "AZ",
								postalCode: "85018"
							}}
						/>
                        <div><a href='tel:4807445700'><PhoneOutlined /> (480) 744-5700</a></div>
                    </Col>
                    <Col span={6} className={classes.footerSection}>
                        <h2 className={classes.sectionHeader}>Products</h2>
                        <div><Button href={`/shippers`} type="link">Shippers</Button></div>
                        <div><Button href={`/carriers`} type="link">Carriers</Button></div>
                    </Col>
                    <Col span={6} className={classes.footerSection}>
                        <h2 className={classes.sectionHeader}>Company</h2>
                        {/* <div><Button href={`/careers`} type="link">Careers</Button></div>
                        <div><Button href={`/investors`} type="link">Investor Relations</Button></div>
                        <div><Button href={`/about`} type="link">About Us</Button></div> */}
                        <div><Button href={`/contact`} type="link">Contact Us</Button></div>
                    </Col>
                    <Col span={6} className={classes.footerSection}>
                        <h2 className={classes.sectionHeader}>Legal</h2>
                        <div><Button href={`/terms`} type="link">Terms of Use</Button></div>
                        <div><Button href={`/privacy`} type="link">Privacy Policy</Button></div>
                        <div><Button href={`/cookies`} type="link">Cookie Notice</Button></div>
                    </Col>
                </Row>
            </section>
            <section style={{ borderTop: '2px solid #e8e8e8', paddingTop: 20 }}>
                <div>
                    <span>IRIS Freight &copy; {new Date().getFullYear()}. All rights reserved.</span>
                    <br />Version {version}
                </div>
            </section>
        </Footer>
    );
};

export default IRISFooter;