import React from 'react';
import { isBooleanFalse, isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import DataRow from '../DataRow/DataRow';

const DataAddressRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = { fontWeight: 'bold' }, title = null, noColon = false, oneLine = false, name = null, streetAddress1 = null, streetAddress2 = null, city = null, state = null, postalCode = null, country = null, address = null }) => {
    if (isObjectNotEmpty(address)) {
        streetAddress1 = address.streetAddress1;
        streetAddress2 = address.streetAddress2;
        city = address.city;
        state = address.state;
        postalCode = address.postalCode;
        country = address.country;
    }

    if (isBooleanTrue(oneLine)) {
        const oneLineText = `${isStringNotEmpty(streetAddress1) ? streetAddress1 + ' ' : ''}${isStringNotEmpty(streetAddress2) ? streetAddress2 + ' ' : ''}${isStringNotEmpty(city) ? city + ', ' : ''}${isStringNotEmpty(state) ? state + ' ' : ''}${isStringNotEmpty(postalCode) ? postalCode + ' ' : ''}${isStringNotEmpty(country) ? country : ''}`;
        
        return (
            <DataRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} title={title} noColon={noColon} value={oneLineText} dataType="String" />
        );
    } else {
        const lastLineText = `${isStringNotEmpty(city) ? city + ', ' : ''}${isStringNotEmpty(state) ? state + ' ' : ''}${isStringNotEmpty(postalCode) ? postalCode + ' ' : ''}${isStringNotEmpty(country) ? country : ''}`;
        
        return (
            <div className={className} style={style}>
                {isStringNotEmpty(title) ? (<div className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </div>) : null}
                {isStringNotEmpty(name) ? (<div className={valueClassName} style={valueStyle}>{name}</div>) : null}
                {isStringNotEmpty(streetAddress1) ? (<div className={valueClassName} style={valueStyle}>{streetAddress1}</div>) : null}
                {isStringNotEmpty(streetAddress2) ? (<div className={valueClassName} style={valueStyle}>{streetAddress2}</div>) : null}
                {(isStringNotEmpty(city) || isStringNotEmpty(state) || isStringNotEmpty(postalCode) || isStringNotEmpty(country)) ? (<div className={valueClassName} style={valueStyle}>{lastLineText}</div>) : null}
            </div>
        );
    }
};

export default DataAddressRow;