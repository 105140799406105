import React from "react";
import TermsOfUse from "../../components/TermsOfUse/TermsOfUse";
import { withRouter } from "react-router-dom";

const Terms = (props) => {
    return (
        <TermsOfUse />
    );
}

export default withRouter(Terms);
