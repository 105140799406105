import React from "react";
import { Button } from "antd";
import { isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";

const FormButtons = ({ containerStyle = null, cancel, disabled = false, submitText, cancelText = "Cancel", cancelDisabled, submitDisabled }) => {
    return (
        <div style={containerStyle}>
            {(isNotNullOrUndefined(cancel) && isStringNotEmpty(cancelText)) ? (<Button type="danger" onClick={cancel} style={{ marginRight: 8 }} disabled={isNotNullOrUndefined(cancelDisabled) ? cancelDisabled : disabled}>{cancelText}</Button>) : null}
            <Button type="primary" htmlType="submit" disabled={isNotNullOrUndefined(submitDisabled) ? submitDisabled : disabled}>{submitText}</Button>
        </div>
    );
};

export default FormButtons;