import React, { useEffect, useRef } from "react";
import { Button, Typography, Row, Col } from 'antd';
import { withRouter } from "react-router-dom";
import shippersImage from "../../assets/img/shippers.jpg";
import carriersImage from "../../assets/img/carriers.jpg";
import traceabilityImage from "../../assets/img/traceability.jpg";
import visibilityImage from "../../assets/img/visibility.jpg";
import card1Image from "../../assets/img/card1.webp";
import card2Image from "../../assets/img/card2.webp";
import card3Image from "../../assets/img/card3.webp";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import CardTitleSubtitle from "../../components/CardTitleSubtitle/CardTitleSubtitle";
import CardTitleBodyWithImage from "../../components/CardTitleBodyWithImage/CardTitleBodyWithImage";
import TitleSubtitle from "../../components/TitleSubtitle/TitleSubtitle";

const { Title } = Typography;

const Home = (props) => {
	let pageHeader = useRef();

	useEffect(() => {
		if (window.innerWidth < 991) {
			const updateScroll = () => {
				let windowScrollTop = window.pageYOffset / 3;
				pageHeader.current.style.transform =
					"translate3d(0," + windowScrollTop + "px,0)";
			};
			window.addEventListener("scroll", updateScroll);

			return function cleanup() {
				window.removeEventListener("scroll", updateScroll);
			};
		}
	});

	return (
		<>
			<div className="page-header" data-parallax={true} ref={pageHeader}>
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} sm={24}>
						<div className="motto">
							<Typography>
								<Title level={1}>END TO END FREIGHT TECHNOLOGY FOR SHIPPERS & CARRIERS</Title>
								<Title level={3}>A better way to ship freight safely, with visibility and traceability, throughout the US.</Title>
							</Typography>
						</div>
					</Col>
				</Row>
				<Row style={{ marginTop: 40 }}>
					<Col span={24} style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
						<Button style={{ marginRight: 40 }} type="default" size="large" className="ShipWithUs" href="/shippers" htmlType="button">SHIP WITH US</Button>
						<Button type="default" size="large" className="ShipWithUs" href="/carriers" htmlType="button">HAUL WITH US</Button>
					</Col>
				</Row>
			</div>
			<div style={{ marginBottom: 40 }}>
				<Row style={{ backgroundColor: 'white' }}>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<SectionHeader size={2} title="SERVICES FOR SHIPPERS AND CARRIERS" />
					</Col>
				</Row>
				<Row style={{ backgroundColor: 'white' }}>
					<Col lg={{ span: 7, offset: 4 }} md={{ span: 8, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<CardTitleBodyWithImage imageSrc={shippersImage} size={4} title="Shippers">
							<ul>
								<li>Increase your capacity through our extended network of Carriers and Owner Operators</li>
								<li>Live, On-Demand Load Tracking and Updates, 24/7</li>
								<li>GPS Monitoring</li>
								<li>Document Management</li>
							</ul>
						</CardTitleBodyWithImage>
					</Col>
					<Col lg={{ span: 7, offset: 2 }} md={{ span: 8, offset: 2 }} xs={{ span: 22, offset: 1 }}>
						<CardTitleBodyWithImage imageSrc={carriersImage} size={4} title="Carriers">
							<ul>
								<li>Let us help us keep your assets moving and your trailers loaded</li>
								<li>Live, On-Demand Load Tracking and Updates, 24/7</li>
								<li>GPS Monitoring</li>
								<li>Document Management</li>
								<li>Quick-Pay and Factoring Options</li>
							</ul>
						</CardTitleBodyWithImage>
					</Col>
				</Row>
				<Row style={{ backgroundColor: 'white' }}>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<TitleSubtitle size={3} title="A curated network for transporting freight reliably from point A to point B" subtitle="Technology and traceability are essential to the future of freight logistics" />
					</Col>
				</Row>
				<Row style={{ backgroundColor: 'white' }}>
					<Col lg={{ span: 7, offset: 4 }} md={{ span: 8, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<CardTitleBodyWithImage imageSrc={traceabilityImage} size={4} title="Traceability">
							Traceability is essential to the protection of the industry’s collective reputation and market share by isolating products from the source of the problem.
          				</CardTitleBodyWithImage>
					</Col>
					<Col lg={{ span: 7, offset: 2 }} md={{ span: 8, offset: 2 }} xs={{ span: 22, offset: 1 }}>
						<CardTitleBodyWithImage imageSrc={visibilityImage} size={4} title="Visibility">
							Loads that are monitored 24/7 hold carriers accountable and ensure a higher likelihood of on-time delivery. Our track-and-trace technology and mobile app allow Shippers to proactively prevent the loss and spoilage of expensive products and shipments.
          				</CardTitleBodyWithImage>
					</Col>
				</Row>
				<Row style={{ backgroundColor: 'white', marginTop: 60 }}>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<Row>
							<Col xl={{ span: 8 }} xs={{ span: 24 }}>
								<CardTitleSubtitle size={3} image={card2Image} title="Reliability" subtitle="For Shippers and Carriers" />
							</Col>
							<Col xl={{ span: 8 }} xs={{ span: 24 }}>
								<CardTitleSubtitle size={3} image={card1Image} title="Food Safety" subtitle="For Perishable Shipments" />
							</Col>
							<Col xl={{ span: 8 }} xs={{ span: 24 }}>
								<CardTitleSubtitle size={3} image={card3Image} title="Accountability" subtitle="Location Tracking for On-Time Delivery and Reduced Detention Times" />
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default withRouter(Home);
