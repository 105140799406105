import React from "react";
import { Col, Row } from "antd";
import { isBooleanTrue, isStringNotEmpty } from "../../shared/objectUtils";

const FormItemDisplay = ({ label, labelCol = {}, wrapperCol = {}, format = 'vertical', required = false, noColon = false, style = null, children }) => {
    if (format === 'vertical') {
        return (
            <>
                {isStringNotEmpty(label) ? (
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0 }}>
                        <Col span={24}>{isBooleanTrue(required) ? '* ' : ''}{label}{isBooleanTrue(noColon) ? '' : ':'}</Col>
                    </Row>
                ) : null}
                <Row gutter={[8, 8]}>
                    <Col span={24} style={style}>
                        {children}
                    </Col>
                </Row>
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <Row gutter={[8, 8]}>
                {isStringNotEmpty(label) ? (
                    <Col {...labelCol} style={{ textAlign: 'right' }}>{isBooleanTrue(required) ? '* ' : ''}{label}{isBooleanTrue(noColon) ? '' : ':'}</Col>
                ) : null}
                <Col {...wrapperCol} style={style}>
                    {children}
                </Col>
            </Row>
        );
    } else {
        return null;
    }
};

export default FormItemDisplay;