import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DrawerLayout from "./hoc/DrawerLayout/DrawerLayout";

const App = () => {
	return (
        <BrowserRouter>
            <Switch>
                <Route path="/" component={DrawerLayout} />
            </Switch>
        </BrowserRouter>
    );
};

export default App;