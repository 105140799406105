import React, { useState, useMemo } from 'react';
import { NavLink, withRouter, Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';
import { Route, Switch } from "react-router";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import classes from "./DrawerLayout.module.scss";
import irisLogoSmall from '../../assets/img/full-logo.png';
import IRISFooter from '../IRISFooter/IRISFooter';
import Home from '../../views/Home/Home';
import Carriers from '../../views/Carriers/Carriers';
import Shippers from '../../views/Shippers/Shippers';
import About from '../../views/About/About';
import Contact from '../../views/Contact/Contact';
import Careers from '../../views/Careers/Careers';
import Terms from '../../views/Terms/Terms';
import Privacy from '../../views/Privacy/Privacy';
import Cookies from '../../views/Cookies/Cookies';
import Investors from '../../views/Investors/Investors';

const { Header, Content } = Layout;

const DrawerLayout = (props) => {

    const location = useLocation();

    const [currentPath, setCurrentPath] = useState(null);

    let routes = (
        <Switch>
            <Route exact path="/carriers" component={Carriers} />
            <Route exact path="/shippers" component={Shippers} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/cookies" component={Cookies} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/investors" component={Investors} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/" component={Home} />
            <Route path="*" render={() => <h1>Not found</h1>} />
        </Switch>
    );

    useMemo(() => {
        let pathParts = location.pathname.split('/');
        let basePath = pathParts[1];
        setCurrentPath('/' + basePath);
    }, [location]);

    return (
        <Layout style={{ minHeight: '100vh' }} hasSider={false}>
            <ScrollToTop />
            <Header className={classes.header} style={{ position: 'fixed', zIndex: 100, width: '100%', overflow: 'hidden' }}>
                <div className={classes.leftMenu}>
                    <Link to="/">
                        <img src={irisLogoSmall} className={classes.topLogo} alt="IRIS" />
                    </Link>

                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[]} activeKey={currentPath} selectedKeys={currentPath}>
                        <Menu.Item key="/shippers"><span>Shippers</span><NavLink to={{ pathname: '/shippers', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                        <Menu.Item key="/carriers"><span>Carriers</span><NavLink to={{ pathname: '/carriers', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                        {/* <Menu.Item key="/about"><span>About Us</span><NavLink to={{ pathname: '/about', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                        <Menu.Item key="/careers"><span>Careers</span><NavLink to={{ pathname: '/careers', }} style={{ textDecoration: 'none' }} /></Menu.Item> */}
                        <Menu.Item key="/contact"><span>Contact Us</span><NavLink to={{ pathname: '/contact', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                    </Menu>
                </div>
                <div className={classes.rightMenu}>
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[]}>
                        <Menu.Item key="/shippers" className="noBackground"><Button type="primary" ghost><span>Ship with Us</span></Button><NavLink to={{ pathname: '/shippers', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                        <Menu.Item key="/carriers" className="noBackground"><Button type="primary" ghost><span>Haul with Us</span></Button><NavLink to={{ pathname: '/carriers', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                        <Menu.Item key="/admin" className="noBackground"><Button type="default" style={{ color: '#000000' }} href="http://admin.irisfreight.com">Sign In</Button></Menu.Item>
                    </Menu>
                </div>
            </Header>
            <Content className={classes.content}>
                {routes}
            </Content>
            <IRISFooter />
        </Layout>
    );
};

export default withRouter(DrawerLayout);