import React from "react";
import { Typography, Card } from 'antd';

const { Title, Paragraph } = Typography;

const CardTitleBodyWithImage = ({ title, size = 3, imageSrc, children }) => {
    return (
        <Card cover={<img alt={title} src={imageSrc} />} bordered={false}>
            <Typography>
                <Title level={size} className="center-text">
                    {title}
                </Title>
                <Paragraph>
                    {children}
                </Paragraph>
            </Typography>
        </Card>
    )
}

export default CardTitleBodyWithImage;